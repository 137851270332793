.infoFull {
    position: relative;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
}

.infoText {
    position: absolute;
    left: 25px;
    top: 0px;
    z-index: 10;
    width: 200px;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
}
.infoText p{
    width: 100%;
}