.ChangeLoginFull {
    width: 100%;
    height: calc(100%);
    background-color: rgba(0, 0, 0, 0.5);
}
.ChangeLoginMain_step2 p{
    cursor: pointer;
}

.ChangeLoginButton {
    display: flex;
    margin-top: 20px;
    justify-content: center;
}

.ChangeMargin {
    margin-right: 10px;
}

.Profile_replace_tel_link {
    color: #407BFF;
    cursor: pointer;
    margin-left: 15px;
}

.Font_size14 {
    font-family: 'Exo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
}

.Font_size24 {
    font-family: 'Exo', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
}

.ChangeLoginMain form {
    margin-bottom: 0px;
}

.ChangeLogin {
    height: auto;
    max-width: 800px;
    width: 100%;
    padding: 20px;
    height: auto;
    z-index: 3;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ChangeLoginMain_step1 p{
    text-align: center;
}
.ChangeLoginMain_step1_description {
    width: 60%;
    display: block;
    margin: auto;
    text-align: center;
    margin-top: 20px;
}
.ChangeLoginMain {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ChangeLoginTitle h1{
    text-align: center;
}
.ChangeLoginMain_step2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ChangeLoginMain_step3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ChangeLoginMain_step2 label{
    width: 100%;
    margin-top: 20px;
    max-width: 400px;
}

.ChangeLoginMain_step3 label {
    width: 100%;
    margin-top: 20px;
    max-width: 400px;
}
.Telephone {
    width: 100%;
    margin-top: 20px;
    max-width: 400px;
}
.Cart_close {
    position: absolute;
    font-size: 30px;
    top: 10px;
    cursor: pointer;
    right: 10px;
}
@media(max-width:580px) {
    .Font_size14 {
        font-family: 'Exo', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
    }
    .ChangeMargin button{
        padding: 22px 0px!important;
        min-width: 160px;
    }
}
@media(max-width:480px) {
    .Cart_close {
       display: none;
    }
    .Font_size14 {
        font-family: 'Exo', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
    }
    .ChangeLoginButton{
        display: flex;
        align-items: center;
    }
    .Font_size24 {
        font-family: 'Exo', sans-serif;
        font-size: 20px !important;
        font-weight: 700 !important;
        line-height: 19px !important;
        letter-spacing: 0px !important;
    }

    .ChangeLogin {
        bottom: 0px;
        border-radius: 16px 16px 0px 0px;
        width: 100%;
        height: auto;
    }

    .ChangeLoginFull{
        display:none;
    }

    .ChangeLoginMain_step1_description {
        width: 80%;
    }
}