.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiperCard {
  max-width: 200px;
  width: 100%;
  border: 2px solid #eaf2ff;
  border-radius: 40px !important;
  padding: 40px 30px 35px 30px;
  min-height: 240px;
  text-decoration: none;
}

.swiperCardImg {
  max-width: 90px;
  margin: 0 auto 12px auto;
  height: 100px !important;
  object-fit: contain !important;
}

.switepCardText {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #2e373d;
}

.CardSwiperTitle {
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  color: #2e373d;
  margin-bottom: 25px;
}

.swiper-button-next,
.swiper-button-prev {
  background: #ffffff;
  border: 2px solid #eaf2ff;
  border-radius: 100px;
  width: 40px;
  height: 40px;
}

.swiper-button-next:after {
  content: "";
  width: 12px;
  height: 10px;
  transform: rotate(-180deg);
  background-image: url('../../../img/arrow.svg');
  background-repeat: no-repeat;
}

.swiper-button-prev:after {
  content: "";
  width: 12px;
  height: 10px;
  background-image: url('../../../img/arrow.svg');
  background-repeat: no-repeat;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #2e373d;
  font-size: 30px;
}

@media (max-width: 980px) {
  .swiperCard {
    max-width: 240px;
    width: 100%;
    border: 2px solid #eaf2ff;
    border-radius: 40px !important;
    padding: 40px 10px 35px 10px;
    min-height: 240px;
    height: 100%;
    text-decoration: none;
  }

  .CardSwiperTitle {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    line-height: 53px;
    color: #2e373d;
    margin-bottom: 25px;
  }
}

@media (max-width: 600px) {
  .swiperCardImg {
    margin: 0 auto 12px auto;
    object-fit: contain !important;
  }

  .swiperCard {
    max-width: 240px;
    width: 100%;
    border: 2px solid #eaf2ff;
    border-radius: 40px;
    padding: 20px 10px 20px 10px;
    min-height: 160px;
    max-height: 240px;
    height: 100%;
    text-decoration: none;
  }

  .switepCardText {
    font-size: 12px;
  }

  .CardSwiperTitle {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    line-height: 21px;
    color: #2e373d;
    margin-bottom: 25px;
  }
}

@media(max-width:768px) {
  .swiperCard {
    max-width: 240px;
    width: 100%;
    border: 2px solid #eaf2ff;
    border-radius: 30px;
    padding: 20px 10px 20px 10px;
    min-height: 200px;
    height: 200px;
    text-decoration: none;
  }
}