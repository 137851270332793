.SliderText{
    margin-top: 40px;
    color: #fff;
    width: 100%;
}
.SliderText h1{
    font-size: 22px;
    margin-bottom: 8px;
}
.SliderText p{
    font-size: 14px;
    margin-bottom: 20px;
}
.Button_Reviws{
    margin-bottom: 30px;
    width: 100%;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}
.SliderLogo {
    display: flex;
    justify-content: center;
}
.SliderLogo img {
    height: 10vh;
    width: auto;
}
.mySwiper {
    padding: 10px;
}
.SliderSlide {
    width: 100%;
    height: calc(100dvh - 20px);
}
.SliderImage {
    display: flex;
    justify-content: center;
}
.SliderImage img {
    height: 50vh;
    width: auto;
}
.SliderVCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

@media(max-width:375px) {
    .SliderText {
        margin-top: 10px;
    }
    .SliderImage img {
        height: 40vh;
    }
}