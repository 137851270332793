label {
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #8b9bab;
  width: 100%;
  user-select: none;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"],
input[type="tel"],
input[type="date"],
input[type="number"],
input[type="email"] {
  background: #ffffff;
  border: 2px solid #eaf2ff;
  border-radius: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #2E373D;
  padding: 20px 28px;
  max-width: 400px;
  width: 100%;
}

input[type="text"]:invalid,
input[type="date"]:invalid,
input[type="tel"]:invalid,
input[type="number"]:invalid,
input[type="email"]:invalid {
  border: 2px solid #ffc9ca;
  background: #fff7f6;;
}

input[type="text"]:focus:invalid,
input[type="date"]:focus:invalid,
input[type="tel"]:focus:invalid,
input[type="number"]:focus:invalid,
input[type="email"]:focus:invalid {
  border: 2px solid #ffc9ca;
  background: #fff7f6;;
}

/* input[type="text"]:valid,
input[type="date"]:valid,
input[type="tel"]:valid,
input[type="number"]:valid,
input[type="email"]:valid {
  background: #e5ffe2;
  border: 2px solid #22e112;
} */

input[type="text"]:not(:focus):invalid,
input[type="date"]:not(:focus):invalid,
input[type="tel"]:not(:focus):invalid,
input[type="number"]:not(:focus):invalid,
input[type="email"]:not(:focus):invalid {
  border: 2px solid #eaf2ff;
  background: #ffffff;
}

input[type="radio"] {
  position: absolute;
  width: 0 !important;
  height: 0;
  appearance: none;
  border: 0px;
}

input[type="radio"]:before {
  position: absolute;
  background: #f3f3f5;
  border: 1px solid #ececee;
  border-radius: 26px;
  content: "";
  cursor: pointer;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  left: -30px;
  top: -3px;
}

input[type="radio"]:invalid:before {
  border: 1px solid #ffc9ca;
}

input[type="radio"]:not(:focus):invalid:before {
  border: 1px solid #ececee;
}

input[type="checkbox"]:invalid:before {
  border: 3px solid #ffc9ca;
}

input[type="checkbox"]:not(:focus):invalid:before {
  border: 3px solid #407bff;
}

input[type="radio"]:checked:after {
  position: absolute;
  content: url("../../img/Radio Button.png");
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  left: -30px;
  top: -3px;
}

input[type="checkbox"] {
  position: absolute;
  width: 0;
  height: 0;
  appearance: none;
  cursor: pointer;
}

input[type="checkbox"]:before {
  position: absolute;
  border: 3px solid #407bff;
  border-radius: 7px;
  content: "";
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  left: -30px;
  top: -3px;
}

input[type="checkbox"]:checked:after {
  position: absolute;
  content: url("../../img/Checkbox.png");
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  left: -30px;
  top: -3px;
}