.Doctor_avatar {
    width: 162px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.ReviewsFull {
    max-width: 770px;
    padding-top: 9px;
}

.blueLink {
    cursor: pointer;
    color: #407BFF;
}

.Message_button_margin {
    margin-right: 20px;
}

.Recording_full p,
h1,
h2 {
    color: inherit;
}

.DoctorOnline {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #22e112;
    position: absolute;
    right: 10px;
    bottom: 15px;
}

.Recording_full {
    margin-left: 30px;
    margin-right: 30px;
}

.Doctor_avatar_img {
    position: relative;
    width: 132px;
    height: 132px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 6px;
}

.Doctor_avatar_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Doctor_info h2 {
    font-size: 24px;
}

.Doctor_info {
    display: flex;
    max-width: 335px;
    flex-direction: column;
}

.Doctor_calendar {
    display: flex;
}

.Staj {
    margin-top: 8px;
}

.Qualification h1 {
    margin-top: 15px;
    margin-bottom: 20px;
}

.Doctor_infos {
    margin-top: 30px;
    display: flex;
    margin-right: 50px;
}

.Doctor_buy {
    margin-top: 21px;
}


.Doctor_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Doctor_avatar_info ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
}

.Doctor_text {
    width: 670px;
    white-space: pre-wrap;
}

.Doctor_info_message {
    margin-top: 10px;
    width: 500px;
}

.Doctor_info_message b {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
}

.Comment {
    border-left: 5px solid #EAF2FF;
    padding-left: 5px;
}

.Doctor_info_unfavorite {
    margin-top: 10px;
}

.Doctor_info_favorite {
    margin-top: 10px;
}

.Doctor_message {
    display: flex;
}

.Doctor_message_avatar {
    position: relative;
    width: 62px;
    height: 62px;
    min-width: 62px;
    border-radius: 50%;
    overflow: hidden;
}

.Doctor_message_avatar img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.Doctor_message_content {
    margin-left: 20px;
}

.Doctor_message_content b {
    margin-bottom: 4px;
}

.Doctor_message_content p {
    margin-bottom: 11px;
}

.Doctor_message {
    margin-top: 11px;
}

.Reviews_buttons_full {
    display: flex;
    max-width: 770px;
    justify-content: center;
}

.Reviews_buttons {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
}

.Show_more {
    padding: 20.5px 53.5px;
    margin-right: 40px;
    background: #FFFFFF;
    border-radius: 16px;
    width: 240px;
    height: 60px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8B9BAB;
    border: 1px solid #9DC3FF;
}

.Doctor_avatar_info p {
    text-align: center;
}

.Reviews_send {
    padding: 20.5px 42.5px;
    background: #F6AE2D;
    border: 0px;
    color: #2E373D;
    letter-spacing: 2px;
    text-transform: uppercase;
    width: 240px;
    height: 60px;
    border-radius: 16px;
}

.Font_size24 {
    font-family: 'Exo', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
}

.LineEndComment {
    border-bottom: 1px solid #EAF2FF;
    width: 100%;
    padding-top: 18px;
}

.Font_size40 {
    font-family: 'Exo', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0px;

}

.Font_size14 {
    font-family: 'Exo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
}

.Font_size12 {
    font-family: 'Exo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
}

.Font_size16 {
    font-family: 'Exo', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
}

.Reviews_title {
    margin-top: 20px;
}

@media (max-width:980px) {
    .Doctor_calendar {
        flex-direction: column;
    }
}

@media(max-width:768px) {
    .Doctor_text {
        max-width: 400px;
        width: auto;
    }

    .Doctor_info_message {
        margin-top: 10px;
        max-width: 300px;
        width: auto;
    }

    .Message_button_margin {
        margin-right: 0px;
    }

    .Reviews_buttons {
        display: flex;
        flex-direction: column;
    }

    .Reviews_buttons button {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media(max-width:460px) {
    .Doctor_message {
        margin-left: -80px;
    }

    .Doctor_infos {
        margin-top: 0px;
        display: flex;
        margin-right: 10px;
    }

    .Doctor_message_avatar img {
        width: 42px;
        border-radius: 50%;
    }

    .Doctor_buy {
        margin-top: 8px;
    }

    .Staj {
        margin-top: 5px;
    }

    .Doctor_avatar {
        max-width: 80px;
        width: auto;
        margin-right: 9px;
    }

    .Qualification h1 {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .Doctor_avatar_img {
        width: 70px;
        height: 70px;
    }

    .Reviews_buttons {
        width: 100%;
    }

    .Doctor_avatar_info ul li img {
        width: 13px;
    }

    .Reviews_title {
        margin-bottom: 9px;
        margin-top: 11px;
    }

    .Doctor_info h2 {
        font-size: 16px;
    }

    .Font_size24 {
        font-family: 'Exo', sans-serif;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 19px !important;
        letter-spacing: 0px !important;
    }

    .Font_size40 {
        font-family: 'Exo', sans-serif;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 53px !important;
        letter-spacing: 0px !important;
    }

    .Font_size14 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
    }

    .Font_size12 {
        font-family: 'Exo', sans-serif;
        font-size: 12px !important;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
    }

    .Font_size16 {
        font-family: 'Exo', sans-serif;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        letter-spacing: 0px !important;
    }

    .Recording_full {
        margin-left: 10px;
        margin-right: 10px;
    }
    .Doctor_message_avatar{
        margin-left: 18px;
    }
}