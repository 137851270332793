.Messages_block {
    position: relative;
}

.Messages_close {
    position: absolute;
    right: 0px;
    top: -5px;
    cursor: pointer;
    font-size: 30px;
}
.Messages_text{
    padding-right: 30px;
}
.Font_size24 {
    font-family: 'Exo', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
}

.Font_size40 {
    font-family: 'Exo', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0px;
    text-align: left;

}

.Font_size14 {
    font-family: 'Exo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
}

.Font_size16 {
    font-family: 'Exo', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
}

.Message_button {
    display: flex;
    margin-top: 40px;
}

.Message_button_margin {
    margin-right: 20px;
}

.text_notice {
    padding: 10px 30px;
    font-weight: normal;
    color: #8b9bab;
    border-bottom: 1px solid #eaf2ff;
}

.Messages_full {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Messages_line {
    margin-top: 7px;
    width: 100%;
    border: 2px solid #EAF2FF;
}

@media(max-width:480px) {
    .Font_size24 {
        font-family: 'Exo', sans-serif;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 22px !important;
        letter-spacing: 0px !important;
    }

    .Message_button_margin {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .Message_button {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .Message_button div {
        width: 100%;
    }

    .Message_button  button {
        width: 100%;
    }

    .Font_size40 {
        font-family: 'Exo', sans-serif;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 53px !important;
        letter-spacing: 0px !important;
    }

    .Font_size14 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        margin-top: 6px;
    }

    .Font_size16 {
        font-family: 'Exo', sans-serif;
        font-size: 10px !important;
        font-weight: 400 !important;
        line-height: 13px !important;
        letter-spacing: 0px !important;
    }
}