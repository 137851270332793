.Skill {
  margin-top: 60px;
}

.blueLink {
  color: #407bff;
}

.SectionDuty p,
h2 {
  color: inherit;
}

.DoctorOnline {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
}

.Doctor_list {
  margin-left: 30px;
  margin-right: 30px;
}

.Skill_title {
  margin-bottom: 26px;
}

.Skill_title h1 {
  font-size: 40px;
}

.Doctor_button {
  display: flex;
  margin-left: 236px;
}

.Doctor_avatar_info p {
  text-align: center;
}

.Doctor_avatar_info {
  margin-top: 20px;
}

.Doctor_avatar {
  width: 162px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 14px;
}

.Doctor_avatar_img {
  position: relative;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 6px;
}

.Doctor_avatar_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Doctor_info h2 {
  font-size: 24px;
}

.Doctor_info {
  display: flex;
  max-width: 325px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  width: 100%;
}

.Staj {
  margin-top: 8px;
}

.Doctor_infos {
  display: flex;
  padding: 20px;
  width: 100%;
}

.Doctor_buy {
  margin-top: 21px;
}

.Doctor {
  display: flex;
  margin-top: 10px;
  padding: 20px;
  width: 925px;
  justify-content: space-between;
  transition-duration: 0.5s;
  border-radius: 10px;
}

.Doctor:last-child {
  margin-bottom: 20px;
}

.Doctor:hover {
  box-shadow: 0px 0px 10px 5px #f3f3f3;
}

.Doctor_list {
  display: flex;
  flex-direction: column;
}

.Doctor_avatar_info ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.Doctor_button {
  margin-bottom: 30px;
  margin-top: 20px;
}

.Select_all {
  display: flex;
}

.Skill_select {
  width: 300px;
  align-items: center;
  display: flex;
}

.Skill_select p {
  margin-right: 14px;
}

.Sort_select {
  align-items: center;
  margin-left: 80px;
  width: 300px;
  display: flex;
}

.gray {
  color: #8b9bab;
}

.Sort_select p {
  margin-right: 14px;
}

.Payment_block {
  margin-top: 20px;
}

.Payment_block p {
  margin-bottom: 5px;
}

.blueLink {
  color: #407bff;
}

.Payment_block button {
  margin-top: 21px;
  padding: 20.5px 44px;
  background: #407bff;
  font-weight: 700;
  border: 0px;
  border-radius: 16px;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

@media (max-width: 980px) {
  .Doctor {
    width: 100%;
  }

  .Skill_title h1 {
    font-size: 40px;
    text-align: center;
  }

  .Skill {
    margin-top: 40px;
  }

  .Skill_title {
    margin-bottom: 30px;
  }

  .Doctor_button {
    display: flex;
    margin-left: 0px;
    justify-content: center;
  }

  .Doctor_button {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .Doctor {
    display: flex;
    flex-direction: column;
  }

  .Sort_select {
    margin-left: 0px !important;
    align-items: flex-start;
  }

  .Skill_select {
    align-items: flex-start;
  }

  .Doctor_avatar {
    max-width: 132px;
    width: auto;
  }

  .Skill {
    margin-top: 0px;
  }

  .Skill_select {
    width: 200px;
    display: flex;
  }

  .Sort_select {
    margin-left: 80px;
    width: 200px;
    display: flex;
  }

  .Select_all {
    display: flex;
    gap: 20px;
  }

  .Sort_select {
    display: flex;
    gap: 5px;
    flex-direction: column;
  }

  .Doctor_avatar {
    padding-left: 0px;
  }

  .Skill_select {
    display: flex;
    gap: 5px;
    flex-direction: column;
  }

  .Doctor_avatar {
    margin-right: 10px;
  }

  .Payment_block {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .Closes_title {
    margin-right: 6px;
  }

  .Payment_block_p {
    margin-top: 10px;
    display: flex;
    padding-left: 20px;
  }

  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
    text-align: left;
  }

  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    text-align: left;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
  }

  .Font_size12 {
    font-family: "Exo", sans-serif;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
    text-align: left;
  }

  .Skill_title {
    display: none;
  }
}

.select {
  position: relative;
  width: 100%;
}

.select__toggle {
  display: flex;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.3125rem;
  cursor: pointer;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.4;
  user-select: none;
  font-size: 1rem;
  justify-content: space-between;
  font-style: italic;
}

.select__toggle::after {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
  margin-left: 1rem;
  background-size: cover;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="100" width="100"%3E%3Cpath d="M97.625 25.3l-4.813-4.89c-1.668-1.606-3.616-2.41-5.84-2.41-2.27 0-4.194.804-5.777 2.41L50 52.087 18.806 20.412C17.223 18.805 15.298 18 13.03 18c-2.225 0-4.172.804-5.84 2.41l-4.75 4.89C.813 26.95 0 28.927 0 31.23c0 2.346.814 4.301 2.439 5.865l41.784 42.428C45.764 81.174 47.689 82 50 82c2.268 0 4.215-.826 5.84-2.476l41.784-42.428c1.584-1.608 2.376-3.563 2.376-5.865 0-2.26-.792-4.236-2.375-5.932z"/%3E%3C/svg%3E');
}

.select__toggle:focus {
  outline: none;
}

.select_show .select__toggle::after {
  transform: rotate(180deg);
}

.select__dropdown {
  display: none;
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  max-height: 10rem;
  overflow-y: auto;
  border-radius: 0.3125rem;
  z-index: 2;
  background-color: #fff;
}

.select_show .select__dropdown {
  display: block;
}

.select_show .select__backdrop {
  display: block;
}

.select__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select__option {
  padding: 0.375rem 0.75rem;
}

.select__option_selected {
  background-color: #e1f5fe;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select__option_selected::after {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  color: #0277bd;
  background-size: cover;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="100" width="100" class="svg-inline--fa fa-check fa-w-16" data-icon="check" data-prefix="fas" aria-hidden="true"%3E%3Cpath d="M33.964 85.547l-32.5-32.251a4.935 4.935 0 010-7.017l7.071-7.017a5.027 5.027 0 017.071 0L37.5 60.987l46.894-46.534a5.028 5.028 0 017.07 0l7.072 7.017a4.935 4.935 0 010 7.017l-57.5 57.06a5.027 5.027 0 01-7.072 0z" fill="%230277bd"/%3E%3C/svg%3E');
}

.select__option:hover {
  background-color: #f5f5f5;
  cursor: pointer;
  transition: 0.2s background-color ease-in-out;
}

@media (max-width: 580px) {
  .Select_all {
    display: block;
    gap: 20px;
    margin-bottom: 40px;
  }
  .Skill_select {
    display: flex;
    margin-bottom: 20px;
    margin-right: 0;
    max-width: 100%;
    width: 100%;
  }
  .Sort_select {
    display: flex;
    margin-bottom: 20px;
    margin-right: 0;
    max-width: 100%;
    width: 100%;
  }
  .Doctor_avatar_img {
    position: relative;
    width: 70px;
    height: 70px;
  }

  .Doctor_avatar {
    max-width: 70px;
    width: auto;
  }

  .Doctor_avatar_info ul li {
    max-width: 20%;
  }

  
  .Doctor_avatar_info {
    margin-top: 10px;
  }

  .Doctor_avatar_info ul li img {
    max-width: 100%;
  }

  .Staj {
    margin-top: 0px;
  }

  .Doctor_buy {
    margin-top: 4px;
  }

  .DoctorOnline {
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 480px) {
  .Doctor_list {
    margin-left: 20px;
    margin-right: 20px;
  }

  .Doctor_button_mobile {
    width: 90%;
  }

  .Doctor_button_mobile button {
    width: 100%;
  }

  .Payment_block button {
    width: 100%;
    margin-top: 5px;
    padding: 20px 20px;
  }

  .Doctor {
    margin-top: 0px;
    padding: 10px 0px;
    margin-bottom: 20px;
  }

  .DisplayNone {
    display: none;
  }
}