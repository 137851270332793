.Med_Cart ul li {
    padding-bottom: 10px;
}

.Med_Cart_info_full {
    display: flex;
}
.sostav{
    display: flex;
}
.Med_Cart ul {
    max-width: 510px;
    list-style: none;
}
.MedCenter{
    display: flex;
    height: 100%;
    border-collapse: collapse;
    border-left: 0px!important;
    border-top: 0px!important;
    flex-direction: column;
    align-items: center;
}
.MedCenter a{
    color: #407BFF;
}
.Med_Cart{
    margin-bottom: 30px;
}
.Med_Cart ul li p{
    position: relative;
}
.Med_Cart ul li p::before{
    position: absolute;
    content: "";
    top:50%;
    left: -15px;
    transform: translate(0,-50%);
    width:3px;
    height: 3px;
    border-radius: 50%;
    background: black;
}
.Med_Cart {
    margin-left: 85px;
    margin-left: 50px;
}

.Med_Cart_Title {
    margin-bottom: 16px;
}

.Med_Cart_Text {
    margin-top: 11px;
}

.Med_Cart_table {
    overflow-x: auto;
}

.Med_Cart_table td {
    border: 1px solid black;
    text-align: center;
    padding: 15px 5px;
}

.Med_Cart_table th {
    padding: 15px 5px;
    border: 1px solid black;
}

.Med_Cart_table table {
    border-collapse: collapse;
    max-width: 619px;
    height: 125px;
}

.Font_size24 {
    font-family: 'Exo', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
}

.Font_size40 {
    font-family: 'Exo', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0px;

}

.Font_size14 {
    font-family: 'Exo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
}

.Font_size16 {
    font-family: 'Exo', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
}

.Font_size16_margin {
    font-family: 'Exo', sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: 0px !important;
    margin-left: 20px;
}

.Med_Cart_info_full a{
    margin-left: 20px;
    height: 100%;
}
.Download_file {
    max-width: 228px;
    width: 100%;
    height: 221px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #EAF2FF;
    border-radius: 24px;
}
thead{
    background: #F9F9F9;
}
.Download_img {
    border-bottom: 2px #9DC3FF solid;
}

.Download_text p {
    width: 161px;
    margin-top: 8px;
    text-align: center;
}
@media(max-width:768px){
    .Med_Cart_Title {
        display: none;
    }
}

@media(max-width:580px) {
    .Med_Cart_info_full a{
        margin-left: auto;
        max-width: 280px;
        width: 100%;
        margin-right: auto;
    }
    .Download_file {
        height: auto;
        padding: 13px 0px;
        width: 100%;
        max-width: 280px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        background: #EAF2FF;
        border-radius: 24px;
    }
    .Med_Cart{
        margin-left: 0px;
    }

    .Download_img {
        border-bottom: 0px #9DC3FF solid;
        border-right: 1px #9DC3FF solid;
    }

    

    .Download_img img {
        width: 35.40px;
        padding-right: 6px;
    }

    .Download_text p {
        color: #407BFF;
        width: 161px;
        margin-top: 0px;
        text-align: center;
    }

    .Med_Cart_info_full {
        flex-direction: column-reverse;
    }

    .Font_size24 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 19px !important;
        letter-spacing: 0px !important;
    }

    .Font_size40 {
        font-family: 'Exo', sans-serif;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 53px !important;
        letter-spacing: 0px !important;
    }

    .Font_size14 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
    }

    .Font_size16 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        letter-spacing: 0px !important;
    }
}