.Cart_close {
  position: absolute;
  transform: rotate(-45deg);
  font-size: 20px;
  color: white;
  right: 30px;
}
.blueLink {
  color: #9dc3ff;
}
.Doctor_cart {
  position: relative;
  margin-top: 0;
  max-width: 800px;
  width: 100%;
  color: #ffffff;
  display: flex;
  padding-bottom: 22px;
  padding-top: 30px;
  padding-right: 40px;
  background-color: #407bff;
  border-radius: 40px !important;
  height: 100%;
}

.Doctor_avatar_info p {
  text-align: center;
}

.Doctor_avatar {
  width: 162px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 14px;
  color: #ffffff;
}

.Doctor_avatar_img {
  position: relative;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 6px;
}

.Doctor_avatar_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Doctor_info h2 {
  font-size: 24px;
}

.Doctor_info p {
  font-size: 14px;
}

.Doctor_info {
  display: flex;
  max-width: 100%;
  flex-direction: column;
}

.Doctor_skills {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #9dc3ff;
}
.Doctor_skills p {
  display: flex;
  align-items: center;
  height: 19px;
}
.Doctor_skills span {
  font-size: 30px;
  margin: 0 5px;
}
.regalia_block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.regalia_block p {
  display: flex;
  align-items: center;
}
.regalia_block span {
  font-size: 30px;
  margin: 0 5px;
}
.Doctor_skills p:nth-last-child(-n + 1) span,
.regalia_block p:nth-last-child(-n + 1) span {
  display: none;
}
.Data {
  display: flex;
  gap: 10px;
  margin-top: 11px;
  margin-bottom: 20px;
}

.Data_consultation {
  padding: 13px 11.5px;
  width: 117px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 15px;
  max-height: 40px;
  padding-left: 16px;
  color: black;
  background-color: #ffffff;
}

.Doctor_avatar_info ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.Data_time p {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.Data_time {
  padding: 13px 9px;
  width: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 15px;
  height: 40px;
  padding-left: 16px;
  color: black;
  background-color: #ffffff;
}
.Data_consultation svg,
.Data_time svg {
  width: 15px;
  height: 15px;
}

.Data_consultation p {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
.Injoy {
  width: 240px;
  padding: 20.5px 37.5px;
  border: 0px;
  background-color: #f6ae2d;
  outline: 0px;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 16px;
}
.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

@media (max-width: 580px) {
  .Doctor_cart {
    border-radius: 15px !important;
    padding-right:10px;
    height: 100%;
  }

  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }

  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 53px !important;
    letter-spacing: 0px !important;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
  }
}
@media (max-width: 480px) {

  .Doctor_info button {
    width: 100%;
    padding: 22px 0px;
  }
  .Data_consultation svg,
  .Data_time svg {
    width: 15px;
    height: 15px;
  }
  .Data_consultation {
    padding: 3px 6px;
    max-width: fit-content;
    border-radius: 15px;
    max-height: 20px;
    border-radius: 5px;
    color: black;
    background-color: #ffffff;
  }
  .Data_time {
    padding: 3px 6px;
    max-width: 67px;
    border-radius: 15px;
    max-height: 20px;
    border-radius: 5px;
    color: black;
    background-color: #ffffff;
  }
  .Doctor_avatar_info p {
    display: none;
  }
  .Doctor_skills {
    align-items: flex-start;
  }
  .Doctor_avatar_img {
    width: 70px;
    height: 70px;
  }

  .Doctor_avatar_info ul li img {
    width: 13px;
  }
  .Cart_close {
    right: 15px;
  }
  .Doctor_avatar {
    margin-right: 10px;
  }

  .Injoy {
    width: 240px;
    padding: 20.5px 37.5px;
    border: 0px;
    margin-left: -70px;
    background-color: #f6ae2d;
    outline: 0px;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 16px;
  }

  .Doctor_avatar {
    width: 80px;
  }

  .Doctor_info {
    width: 70%;
  }
}
