.Chat_list ul li button {
  cursor: pointer;
  background: #f6ae2d;
  color: black;
  margin: 0px;
  text-transform: none;
  padding: 0px;
}

.Chat_list ul li{
  border-radius: 16px;
  padding: 4px 15px;
  background: #f6ae2d;
  margin-top: 10px;
}

.Chat_mod {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
}

.Chat_list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Chat_icon>img {
  position: fixed;
  right: 15px;
  z-index: 99;
  bottom: 15px;
  padding: 15px;
  background: #9dc3ff;
  border-radius: 16px;
}

.Chat_icon {
  position: relative;
}

.Chat_icon>img {
  cursor: pointer;
}

.Chat_list ul {
  display: flex;
  list-style: none;
  flex-direction: column;
  align-items: center;
}

.Cart_slose {
  position: absolute;
  font-size: 30px;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 3;
}

.imgAbsol {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Chat_full {
  max-width: 400px;
  width: 100%;
  height: 100%;
  max-height: 600px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  z-index: 100;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  right: 0px;
}

.Chat_list button {
  padding: 20.5px 66px;
  background: #407bff;
  border-radius: 16px;
  margin-top: 40px;
  cursor: pointer;
  letter-spacing: 2px;
  border: 0px;
  color: #ffffff;
  text-transform: uppercase;
}

.Chat_message_title {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
}

.DoctorName {
  max-width: 160px;
}

.GreenDot {
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: #22e112;
  border-radius: 50%;
  left: 40px;
  bottom: 15px;
}

.Chat_message_title img {
  border-radius: 50%;
}

.message_send {
  display: flex;
  align-items: center;
  width: 100%;
}

.message_input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
}

.message_input input {
  height: 44px;
  background: #ffff !important;
  border: 2px solid #eaf2ff !important;
}

.Chat_message {
  padding: 12px 20px;
  width: 100%;
}

.Chat_message_title>img {
  width: 52px;
  margin-right: 14px;
}

.TimeMess {
  font-size: 12px;
}

.main_message {
  height: 440px;
  overflow-y: auto;
  display: flex;
  border-top: 1px solid #eaf2ff;
  align-items: flex-end;
  border-bottom: 1px solid #eaf2ff;
}

.main_messageText {
  width: 100%;
  padding-bottom: 17px;
}

.main_messageText>p {
  text-align: center;
  margin-bottom: 15px;
}

.message_send {
  height: 70px;
  position: relative;
}

.MessageDoctor {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.MessageDoctor .MessageText {
  margin-right: 10px;
  max-width: 227px;
  padding: 5px 11px;
  border-radius: 7px 7px 7px 0px;
  width: 100%;
  background: #e6e6e6;
}

.MessageMe .MessageText {
  margin-left: 10px;
  max-width: 227px;
  padding: 5px 11px;
  border-radius: 7px 7px 7px 0px;
  width: 100%;
  background: #eaf2ff;
}

.MessageMe {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
  align-items: center;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.ChatUtility li {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 85px;
  text-align: center;
  align-items: center;
}

.MobileChat {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 30px;
}

.ChatUtility li .icon_back {
  padding: 11px;
  box-sizing: border-box;
  height: 42px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
}
.ChatUtility li .icon_back img{
  width: 20px;
}
@media (max-width:480px) {
  .main_message {
    height: 235px;
    overflow-y: auto;
    display: flex;
    align-items: flex-end;
  }

  .Cart_slose {
    display: none;
  }

  .Chat_close {
    position: fixed;
    cursor: default;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: calc(100% - 400px);
  }

  .Chat_full {
    max-width: 480px;
    width: 100%;
    height: 100%;
    max-height: 600px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 0px 0px;
    display: flex;
    z-index: 20;
    background: #ffffff;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    right: 0px;
  }

  .Chat_message_title {
    padding-right: 30px;
  }

  body.Chat_full {
    overflow: hidden;
  }

  .Chat_full {
    display: none;
  }
}