.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 2px;
    /* Specified height */
    background: #407BFF;
    /* Grey background */
    outline: none;
    /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 10.5px;
    /* Set a specific slider handle width */
    height: 10.5px;
    /* Slider handle height */
    background: #407BFF;
    /* Green background */
    cursor: pointer;
    border-radius: 50%;
    /* Cursor on hover */
}