.Doctor_avatar_info ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.SectionDuty p,
h1 {
  color: inherit;
}

.Container {
  padding-left: 31px;
  padding-right: 31px;
  margin-top: 30px;
}

.ClassNull {
  text-decoration: none;
}

.medicine > a {
  margin-top: 10px;
}

.medicine {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.black {
  color: black !important;
}

.Doctor_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Doctor {
  display: flex;
  margin-bottom: 40px;
}

.Title_list h1 {
  margin-top: 50px;
  margin-bottom: 25px;
}

.Doctor_cart {
  position: relative;
  margin-top: 80px;
  width: 800px;
  display: flex;
  padding-bottom: 22px;
  padding-top: 30px;
  background-color: #407bff;
  border-radius: 40px;
}

.Doctor_avatar_info p {
  text-align: center;
}

.Doctor_avatar {
  width: 162px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 14px;
  color: #ffffff;
}

.Doctor_avatar_img {
  position: relative;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 6px;
}

.Doctor_avatar_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Doctor_info h2 {
  font-size: 24px;
}

.Doctor_info p {
  font-size: 14px;
}

.Doctor_info {
  display: flex;
  color: #ffffff;
  width: 335px;
  flex-direction: column;
}

.Doctor_skills {
  color: #9dc3ff;
}

.Data {
  display: flex;
  margin-top: 11px;
  margin-bottom: 20px;
}

.Data_consultation {
  padding: 13px 11.5px;
  width: 117px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  max-height: 40px;
  padding-left: 16px;
  color: black;
  background-color: #ffffff;
}

.Data_consultation p {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.medicine_title {
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  margin-bottom: 27px;
}

.medicine_cards {
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}

.card_item {
  border-radius: 30px !important;
  width: 270px !important;
  height: 306px !important;
}

.card_item img {
  max-width: 270px;
  display: block;
  margin: 0 auto;
  height: 136px;
}

.card_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 11px;
  margin-top: 25px;
}

.card_subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

.card_text_wrapper {
  padding: 0 30px 50px 30px;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

@media (max-width: 980px) {
  .Doctor_cart {
    width: 500px;
  }
  .medicine {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Title_list h1 {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .Container {
    margin-top: 0;
  }
}

@media (max-width: 580px) {
  .Doctor_cart {
    width: 280px;
  }

  .card_item {
    width: 130px !important;
    height: 150px !important;
    border-radius: 10px !important;
  }

  .card_item img {
    max-width: 130px;
    max-height: 90px;
    height: auto;
  }

  .card_title {
    font-size: 14px;
    line-height: 14px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .card_subtitle {
    font-size: 12px;
    line-height: 13px;
  }

  .card_text_wrapper {
    padding: 0px 10px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 70px;
  }

  .Doctor_avatar_info p {
    display: none;
  }

  .Title_list h1 {
    margin-top: 30px;
    text-align: center;
    margin-bottom: 0px;
  }

  .Doctor_avatar_img {
    width: 70px;
  }

  .Doctor_avatar_info ul li img {
    width: 13px;
  }

  .Doctor_avatar {
    margin-right: 10px;
  }

  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }

  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 53px !important;
    letter-spacing: 0px !important;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
  }

  .Data_consultation p {
    text-align: center;
    height: 14px;
  }

  .Injoy {
    width: 240px;
    padding: 20.5px 37.5px;
    border: 0px;
    margin-left: -70px;
    background-color: #f6ae2d;
    outline: 0px;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 16px;
  }

  .Container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .Doctor_avatar {
    width: 80px;
  }

  .Doctor_info {
    width: 180px;
  }

  .medicine_title {
    margin-bottom: 0px;
  }
}
