.Cancel_Record_full {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 16px 16px;
    z-index: 30;
    top: 0px;
}

.Cancel_Record_button button {
    max-width: 240px;
    width: 100%;
    height: auto;
    padding: 22px 20px;
    letter-spacing: 2px;
    margin-top: 45px;
    text-transform: uppercase;
    border-radius: 16px;
}
.Cart_close {
    position: absolute;
    font-size: 30px;
    top: 10px;
    cursor: pointer;
    right: 10px;
}
.Cancel_Record_button {
    width: 100%;
    gap: 20px;
    display: flex;
    justify-content: center;
}

.Cancel_Record_block {
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    z-index: 3;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 16px;
    padding-bottom: 40px;
}

.Cancel_Record {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonActive {
    background: #F6AE2D;
    border: 0px;
    margin-right: 30px;
}

.buttonNo {
    background: #ffffff;
    border: 1px solid #9DC3FF
}

.Cancel_Record h1 {
    text-align: center;
}

.Font_size24 {
    font-family: 'Exo', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
}

@media (max-width:520px) {
    .Cancel_Record_button {
        display: flex;
        gap: 30px;
        align-items: center;
    }

    .Font_size24 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 19px !important;
        letter-spacing: 0px !important;
    }

    .Cancel_Record button {
        max-width: 240px;
        width: 100%;
        height: auto;
        padding: 13.5px 18px;
        cursor: pointer;
        letter-spacing: 2px;
        margin-top: 25px;
        text-transform: uppercase;
        border-radius: 16px;
    }

    .buttonActive {
        margin-right: 0px;
        margin-top: 25px !important;
    }

    .Cancel_Record_block {
        max-height: 600px;
    }
}

@media(max-width:480px) {
    .Cancel_Record_block img {
        display: none;
    }
    .Cart_close {
        display: none;
     }
    .Cancel_Record button {
        margin-top: 20px;
    }

    .Cancel_Record_block {
        bottom: 0px;
        position: static;
        top:auto;
        transform: translate(0%,0%);
        width: 100%;
        border-radius: 16px;
        left:0px;
        padding-bottom: 0px;
        height: 100%;
        margin-bottom: 15px;
        max-height: 300px;
        min-height: auto;
    }
}