.Cancel_Record_full {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate(0%,0%);
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0px;
    z-index: 30;
    top: 0px;
}
.Cart_close {
    position: absolute;
    font-size: 30px;
    top: 10px;
    cursor: pointer;
    right: 10px;
}
.Cancel_Record_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 3;
    max-height: 800px;
    max-width: 800px;
    padding: 0px 40px;
    border-radius: 16px;
    background-color: #ffffff;
}
.icon_back{
    padding: 11px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    max-width: 85px;
    text-align: center;
    align-items: center;
    height: 42px;
    width: 42px;
    margin-bottom: 5px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
}
.moduleIcon{
    display: flex;
    align-items: center;
    color: #ffffff;
    flex-direction: column;
}
.UnityTitle{
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%,0%);
}