.icon_back img {
    width: 20px !important;
}

.icon_close {
    background: #F14646 !important;
}

.videoDesktop {
    display: flex !important;
}

.videoDesktop li {
    cursor: pointer;
    width: 85px;
}

.videoMobile {
    display: none;
}

.videoMobile li p {
    font-size: 8px;
}

@media(max-width:600px) {
    .videoDesktop {
        display: none !important;
    }

    .videoMobile {
        display: flex !important;
    }
    
    .videoMobile li {
        max-width: 40px !important;
    }
}