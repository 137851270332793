.Utility_Check_video {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    background: #2E373D;
    border-radius: 16px;
}

.Utility_full {
    padding-left: 75px;
    min-height: 700px;
}

.Utility_text p {
    margin: 12px 0px;
    text-align: left;
}

.Utility_configuration_video {
    display: flex;
    flex-direction: column;
    max-width: 241px;
}

.Utility_configuration_volume {
    width: 250px;
    display: flex;
    flex-direction: column;
}

.Utility_Check_video video::-webkit-media-controls {
    display: none !important;
    -webkit-appearance: none;
}

.Utility_Check_video video::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

.Utility_configuration_volume>input {
    margin-top: 15px;
}

.Utility_configuration_video>input {
    margin-top: 15px;
}

.Utility_Check_video_content {
    flex-direction: column;
    align-items: center;
    display: flex;
}

.Utility_Check_video_content button {
    background: #F6AE2D;
    border-radius: 16px;
    width: 240px;
    height: 60px;
    margin-top: 25px;
    border: 0px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000;
    padding: 22px 36px;
}

.check_volume {
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
}

.Utility_configuration_video {
    margin-right: 18px;
    width: 300px;
}

.Utility_configuration {
    display: flex;
}

.Utility_configuration p {
    margin-bottom: 14px;
    text-align: left;
}

.Utility_volume p {
    margin-top: 20px;
    text-align: left;
    margin-bottom: 14px;
}

.Utility_items {
    display: flex;
    margin-bottom: 80px;
}

.Utility_item_active {
    width: 20px;
    height: 10px;
    background: #407BFF;
    border-radius: 85px;
    margin-right: 5px;
}

.Utility_item_unactive {
    width: 20px;
    height: 10px;
    background: #EAF2FF;
    border-radius: 85px;
    margin-right: 5px;
}