.gray {
  color: #8b9bab;
}
.Reviews_buttons {
  margin-top: 0px;
  margin-bottom: 70px;
  display: flex;
}
.DoctorOnline {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #22e112;
  position: absolute;
  right: 0;
  bottom: 0;
}
.Reviews_buttons {
  display: flex;
  margin-left: 192px;
}
.Show_more {
  padding: 20.5px 53.5px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  width: 240px;
  height: 60px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #8b9bab;
  border: 1px solid #9dc3ff;
}
.Doctor {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  max-width: 620px;
  position: relative;
  justify-content: space-between;
  margin: 16px;
  padding: 16px;
  border: 2px solid #eaf2ff;
  border-radius: 20px;
}

.Doctor_info button {
  background: #407bff;
  border-radius: 16px;
  width: 200px;
  height: 60px;
  margin-top: 25px;
  border: 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 22px 41px;
}

.Doctor_infos {
  display: flex;
}

.Doctor_info {
  flex: 1;
}

.Doctor_avatar_info {
  margin-top: 10px;
}

.Doctor_avatar_info p {
  text-align: center;
}

.Doctor_avatar {
  width: 162px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.Doctor_avatar_img {
  position: relative;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 6px;
}

.Doctor_avatar_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Doctor_info h2 {
  font-size: 24px;
}

.Doctor_info p {
  font-size: 14px;
}

.Cart_close {
  position: absolute;
  transform: rotate(-45deg);
  font-size: 20px;
  right: -27px;
  cursor: pointer;
}

.buy {
  font-size: 24px !important;
}

.Doctor_avatar_info ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.Data_time {
  margin-top: 8px;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}
.Doctor_button {
  display: none;
}
@media (max-width: 980px) {
  .Reviews_buttons {
    display: flex;
    margin-left: 0px;
    justify-content: center;
  }
}

@media (max-width: 580px) {
  .Doctor_avatar {
    margin-right: 10px;
  }
  .Doctor_infos {
    padding-right: 45px;
  }
}
@media (max-width: 480px) {
  .Doctor_avatar_img {
    width: 70px;
    height: 70px;
  }
  .Record_desktop_sceleton {
    display: none !important;
  }
  .Doctor_avatar {
    margin-right: 0px;
    width: 90px;
  }
  .Doctor_button {
    display: block;
  }
  .Doctor_button {
    margin: 0 auto;
    width: 100%;
  }
  .Doctor_button button {
    border-radius: 16px;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border: 0px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 13.5px 21px;
  }
  .Reviews_buttons_mobile {
    width: 95%;
  }
  .Reviews_buttons_mobile button {
    width: 100%;
  }
  .Doctor_avatar_info ul li img {
    width: 15px;
  }

  .Doctor_info button {
    display: none;
  }

  .Doctor_avatar {
    margin-right: 10px;
  }

  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }

  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 53px !important;
    letter-spacing: 0px !important;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
  }
}
