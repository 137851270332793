.formDownload p {
  margin-top: 12px;
  margin-bottom: 9px;
}
.ResultFull {
  padding-left: 45px;
}
.formDownload {
  max-width: 500px;
}
.formDownload input {
  width: 100%;
}
.Form_Download label svg {
  display: none;
}
.labelDownload {
  position: absolute;
  left: 50%;
  font-size: 12px;
  max-width: 320px;
  z-index: -1;
  border: 0px !important;
  width: auto;
  margin: 0px !important;
  height: auto !important;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Form_Download label span {
  text-align: center;
  display: none;
}
.Download_file_flex {
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
}
.Download_File_PDF .Form_Line {
  margin-top: 0px;
  margin-bottom: 21px;
}
.Form_Download > label {
  height: 100%;
  border: 2px dashed #eaf2ff;
  border-radius: 24px;
  text-decoration: none;
}
.Message_button_margin {
  display: flex;
  justify-content: flex-start;
}
.Form_Input {
  position: relative;
  display: flex;
  align-items: center;
}
.Form_Radio {
  margin-top: 10px;
  display: flex;
  margin-left: 30px;
}
.Info {
  margin-left: 10px;
}
.Form_Radio label {
  margin-left: 0px;
  width: auto;
}
.Register_radio2 {
  margin-right: 40px;
}
.Download_File_full {
  max-width: 500px;
}
.Form_Download {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  position: relative;
  color: #8b9bab;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Form_Line {
  border: 1px solid #eaf2ff;
  margin-top: 14px;
  max-width: 500px;
  margin-bottom: 30px;
}
.Download_File_left {
  display: flex;
  align-items: flex-start;
}
.Download_File_left_text p {
  margin-bottom: 8px;
}
.Download_File_block {
  display: flex;
  justify-content: space-between;
}
.Download_File_right_text {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}
.Download_File_right_text img {
  margin-right: 10px;
  margin-top: -7px;
}
.Form_Radio label {
  margin-bottom: 10px;
}
.formDownload button {
  border-radius: 16px;
  margin-top: 5px;
  border: 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 19px 44px;
}
.Download_File_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Download_File_left img {
  margin-right: 9px;
}
.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
}
.UploadFile {
  display: flex;
  justify-content: center;
}
.UploadFile button {
  display: block;
  width: 100%;
}
.gray {
  color: #8b9bab;
}
.AttachFile {
  font-size: 14px;
  cursor: pointer;
  display: block;
  padding: 6px;
  border: 1px solid rgb(97, 97, 97);
  border-radius: 10px;
  text-align: center;
}
@media (max-width: 980px) {
  .formDownload button {
    display: block;
  }
  .ResultFull {
    padding-left: 35px;
  }
  /* .Form_Download {
    display: none;
  } */
  .UploadFile {
    display: flex;
    justify-content: flex-start;
  }
  .UploadFile button {
    width: 100%;
    padding: 19px 44px;
    max-width: 400px;
  }
}
@media (max-width: 840px) {
  .ResultFull {
    padding-left: 0px;
  }
}
@media (max-width: 768px) {
  .title {
    display: none;
  }
}
@media (max-width: 580px) {
  .Form_Radio {
    flex-direction: column;
  }
  .Form_Input {
    margin-top: 10px;
  }
  .Form_Input label {
    margin-left: 10px !important;
  }
  form input {
    margin-bottom: 4px;
    padding: 12px 18px !important;
  }
}
@media (max-width: 480px) {
  .title {
    display: none;
  }
  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }
  .Message_button_margin {
    width: 100%;
  }
  .Message_button_margin button {
    width: 100%;
  }
  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 53px !important;
    letter-spacing: 0px !important;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
  }
}
