.RequestMoney {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.RequestMoneyFull {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 500px;
}

.RequestMoney input {
  margin: 0;
  min-width: 190px;
}

.Cart_close {
  position: absolute;
  transform: rotate(-45deg);
  font-size: 30px;
  cursor: pointer;
  right: 20px;
  top: 10px;
}

.blue {
  display: flex;
  color: #407bff;
  cursor: pointer;
  align-items: center;
}

.blue p {
  margin-left: 10px;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
}

.Font_size14 {
  color: #8b9bab;
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  margin-top: 20px;
}

.background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 21;
  top: 0px;
  width: 100%;
  height: 100%;
}
@media (max-width: 580px){
  .RequestMoneyFull {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
  }
}
@media (max-width: 480px) {
  .mob_block {
    margin-bottom: 15px;
  }
  .RequestMoney {
    gap: 0;
  }
  .Cancel_Record_block {
    left: 50%;
    top: auto;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    transform: translate(-50%, 0%);
    bottom: 0px;
    padding: 20px;
  }

  .Cart_close {
    display: none;
  }

  .RequestMoney {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    margin-top: 20px;
  }

  .RequestMoney button {
    width: 100%;
  }

  .background {
    position: fixed;
    background-color: unset;
    z-index: 21;
    top: 0px;
    width: 100%;
    height: 100%;
  }
}