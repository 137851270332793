.videochat-layout__video {
    height: 100%;
}
.streamComponent {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.streamComponent video {
    width: auto;
    height: 100%;
}

.message__content {
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    padding: 0 10px;
}
.message__content .message__doctor:last-child,
.message__content .message__my:last-child {
    margin-bottom: 10px;
}
.message__content::-webkit-scrollbar { 
    display: none; 
}

.message__doctor {
    display: flex;
    margin-top: 10px;
    flex-flow: column;
}
.message__my {
    display: flex;
    margin-top: 10px;
    flex-flow: column;
}

.message__doctor_text {
    max-width: 293px;
    border-radius: 7px;
    margin-right: 12px;
    padding: 10px;
    background: #E6E6E6;
    word-break: break-word;
    text-align: left;
}
.message__my_text {
    border-radius: 7px;
    padding: 10px;
    margin-left: 10px;
    background: #EAF2FF;
    word-break: break-word;
    text-align: right;
}

.message_image {
    position: relative;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 6px;
  }
  
  .message_image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

.from__doctor {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: left;
}
.from__my {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
}
.message_sender {
    margin: 0 10px;
}
.message_block__doctor {
    display: flex;
    width: 100%;
    justify-content: left;
    flex-direction: row-reverse;
}
.message_block__my {
    display: flex;
    width: 100%;
    justify-content: right;
}
.message_block__doctor .gray_config, 
.message_block__my .gray_config {
    padding: 10px 0;
}