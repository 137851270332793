.VideoFull {
    margin-top: 70px;
    padding: 0px 25px;
}

.VideoTitle {
    margin-bottom: 30px;
}

.VideoBlock {
    display: flex;
    padding-bottom: 70px;
    width: 100%;
    height: 70vh;
}

.VideoBlockImage {
    width: 70%;
    position: relative;
    background-color: #000000;
}

.VideoBlockImageSkeleton {
    background-color: inherit;
}

.background {
    position: fixed;
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 100%;
}

.VideoChat {
    margin-left: 20px;
    position: relative;
    width: 30%;
}

.VideoBlockImage>img {
    width: 100%;
    border-radius: 30px;
}

.Video_Doctor_spec {
    color: #8B9BAB;
}

@media(max-width:980px) {
    .VideoBlock {
        flex-direction: column;
        height: 100%;
        padding-bottom: 40px;
    }

    .VideoFull {
        margin-top: 0px;
        padding: 0px 25px;
    }

    .VideoTitle {
        text-align: center;
    }

    .VideoChat {
        margin-left: 0px;
        width: 100%;
        height: 40vh;
        margin-top: 20px;
    }

    .VideoBlockImage {
        width: 100%;
        height: 70vh;
    }

    .MessageText {
        width: 100%;
        padding-bottom: 40px;
    }
}

@media(max-width:600px) {
    .VideoChat {
        display: none;
    }

    .VideoFull {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media(max-width:480px) {
    .VideoFull {
        padding: 0px 0px;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        height: calc(100% - 41px);
    }

    .VideoTitle {
        display: none;
    }

    .VideoBlockImage {
        height: 100%;
    }
}
