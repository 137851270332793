.form_container {
  padding-top: 5vw;
  max-width: 720px;
  padding: 5vw 20px;
  margin: 0 auto;
}
.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #2e373d;
  margin-bottom: 30px;
  max-width: 720px;
}
.form_body {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.radio_block {
  display: flex;
  align-items: center;
  column-gap: 65px;
  font-weight: 400;
  font-size: 16px;
  margin-bottom:15px;
  line-height: 20px;
  color: #8b9bab;
}
.policy {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #2e373d;
  max-width: 350px;
  padding-left: 10px;
}
.policyBlock {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-left: 30px;
  width: 100%;
}
.policyLink {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #407bff;
  text-decoration: none;
}
@media(max-width:678px){
    .title{
      font-size: 16px;
      line-height: 21px;
    }
    .radio_block {
      column-gap: 45px;
    }
}