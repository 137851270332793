#Select-menu label {
    border-collapse: collapse;
    width: 100%;
    padding: 5px 0px;
    display: flex;
    align-items: center;
    height: 50px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    padding-left: 15px;
}

#Select-menu label:hover {
    background-color: #ECECEE;
}

#Select-menu {
    width: 100%;
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
    z-index: 10;
    top: 50px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
}

#Select-menu [type="radio"]:checked+label::before,
#Select-menu [type="radio"]:not(:checked)+label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    width: 0px;
    height: 0px;
    background: #F3F3F5;
    border: 0px solid #ECECEE;
    border-radius: 100%;
    background: #fff;
}

#Select-menu input[type=radio]:before {
    display: none;
}

#Select-menu [type="radio"]:checked,
#Select-menu [type="radio"]:not(:checked) {
    outline-style: none;
    position: absolute;
    width: 100% !important;
    left: 0px;
    top: 0px;
    cursor: pointer;
    height: 50px;
    border: 0px;
}

#Select-menu [type="radio"]:checked+label:after,
#Select-menu [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 0px;
    height: 0px;
    background: #407BFF;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#Select-hide {
    width: 70%;
    position: relative;
    height: 43px;
    max-width: 300px;
}

#Select-hide1 {
    width: 100%;
    position: relative;
    max-width: 300px;
}

#Select-hide1 p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    padding: 10px 0px;
    padding-left: 16px;
    margin: 0px;
    border: 1px solid #9DC3FF;
    border-radius: 7px;
}

#Select-hide p {
    padding: 10px 0px;
    white-space: nowrap;
    padding-left: 16px;
    margin: 0px;
    border-radius: 10px;
}

.background{
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
}

.Select_content {
    position: relative;
    overflow:hidden;
}

.Rotate_img {
    transform: rotate(-180deg);

}

.Select_content img {
    position: absolute;
    top: 50%;
    border-radius: 10px;
    right: 1px;
    background: #fff;
    padding: 10px 10px;
    transform: translate(0,-50%);
}

@media (max-width: 768px) {
    #Select-hide {
        width: 100%;
        max-width: 300px;
    }
}
@media (max-width:480px){
    #Select-hide p {
        font-size: 14px;
    }
    .Select_content img {
        position: absolute;
        top: 5px;
        border-radius: 10px;
        right: 1px;
        background: #fff;
        padding: 10px 10px;
        transform: translate(0,0%);
    }
    .Select_content_text {
        font-size: 14px;
    }
}
@media(max-width:580px) {
    #Select-hide {
        width: 100%;
        max-width: 100%;
    }
}