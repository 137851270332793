.Left_Position ul li p {
  text-transform: uppercase;
}

.My_Profile {
  margin-top: 45px;
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
}

.Left_Position {
  max-width: 305px;
  margin-right: 30px;
}

.Left_Position ul {
  list-style: none;
}

.My_content_top {
  display: flex;
}

@media (min-width: 480px) {
  .Edit_account {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 840px) {
  .Edit_account {
    display: flex;
    justify-content: left;
    width: fit-content;
  }
  .My_content_top_base {
    width: fit-content;
  }
  .My_content_top {
    width: fit-content;
  }
}

.My_content_top_base {
  display: flex;
  padding: 16px;
  border: 2px solid #eaf2ff;
  border-radius: 20px;
  flex-direction: column;
  gap: 10px;
}

.My_content {
  margin-top: -15px;
  margin-left: 40px;
  flex: 1;
}

.My_content h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

.My_content_top_image {
  position: relative;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
}

.My_content_top_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.My_content_top_content {
  margin-left: 36px;
  width: 100%;
}

.My_content_top_content h1 {
  font-size: 24px;
  max-width: 280px;
  width: 100%;
  margin-right: 10px;
}

.My_content_bottom span {
  display: flex;
  margin-top: 25px;
}

.My_content_top_content {
  max-width: 280px;
  position: relative;
}

.My_content_top_title {
  display: flex;
}

.My_content .Left_Position {
  display: none;
}

.My_content_top_title img {
  height: 18px;
  margin-top: 10px;
}

.navLi:hover {
  border-color: #407bff;
}

.Menu_mobile {
  display: none;
}

.navLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  min-height: 20px;
  margin-bottom: 30px;
  transition-duration: 0.5s;
  text-align: center;
}

.navLink:hover {
  color: #407bff;
}

.headerActiveLink {
  color: #407bff;
}

.links {
  padding-bottom: 40px;
}

.close {
  color: #f14646 !important;
}

.links a p {
  margin-bottom: 5px;
}

@media (max-width: 840px) {
  .My_content_title h1 {
    display: none;
  }

  .My_content_top {
    display: flex;
    justify-content: center;
  }

  .My_content_bottom {
    display: none;
  }

  .My_content_top_content {
    margin-left: 5px;
  }

  .My_content_top_title h1 {
    font-size: 16px;
    margin-top: auto;
    margin-right: 10px;
    margin-bottom: auto;
  }

  .My_content_top_image img {
    width: 70px;
    height: 70px;
  }

  .My_content_top_image {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
  }

  .My_Profile {
    flex-direction: column-reverse;
  }

  .Left_Position {
    margin-top: 37px;
    width: 100%;
    max-width: 1920px;
    display: none;
    justify-content: center;
  }

  .My_content {
    display: flex;
    flex-direction: column-reverse;
  }

  .My_content .Left_Position {
    display: flex;
  }

  .My_content {
    margin-top: 0px;
    margin-left: 0px;
  }

  .links {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .My_content .Left_Position {
    display: flex;
    flex-direction: column;
  }

  .Left_Position ul li {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 580px) {
  .My_Profile {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
  }
}
