.Container {
    margin-right: 35px;
    margin-left: 35px;
}

.ResultTitle {
    margin-bottom: 20px;
}

.ResultInfo {
    margin-bottom: 20px;
}

.ResultList {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
}

.ResultBlock {
    width: 50%;
    display: flex;
}

.ResultBlockLogo {
    margin-right: 6px;
}

.ResultBlock:not(:last-child) {
    margin-bottom: 20px;
}

@media(max-width:768px) {
    .Container {
        margin-right: 10px;
        margin-left: 10px;
    }

    .ResultBlock {
        width: 100%;
    }
}