.Doctor_avatar_info ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}
.Doctor_avatar_info {
  margin-top: 20px;
}
.Doctor_full p,
h1 {
  color: inherit;
}
.DoctorOnline {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #22e112;
  position: absolute;
  right: 0;
  bottom: 0;
}

.Consultation_title {
  margin-top: 40px;
  margin-bottom: 20px;
}

.My_consultation {
  margin-right: 35px;
  margin-left: 35px;
}

.Doctor_cart {
  position: relative;
  margin-top: 25px;
  width: 800px;
  display: flex;
  padding-bottom: 22px;
  padding-top: 30px;
  background-color: #407bff;
  border-radius: 40px;
  margin-bottom: 60px;
}

.Doctor_avatar_info p {
  text-align: center;
}

.Doctor_avatar {
  width: 162px;
  position: relative;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 14px;
  color: #ffffff;
}

.Doctor_avatar_img {
  position: relative;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 6px;
}

.Doctor_avatar_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Doctor_info h2 {
  font-size: 24px;
}

.Doctor_info p {
  font-size: 14px;
}

.Doctor_skills {
  color: #9dc3ff;
}

.Data {
  display: flex;
  margin-top: 11px;
  margin-bottom: 20px;
}

.Data_consultation {
  padding: 13px 11.5px;
  width: 117px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  height: 40px;
  padding-left: 16px;
  color: black;
  background-color: #ffffff;
}

.Data_consultation p {
  font-weight: 400;
  font-size: 16px;
  margin-top: -4px;
}

.Cart_close {
  position: absolute;
  transform: rotate(-45deg);
  font-size: 20px;
  color: white;
  right: 30px;
}

.Cart_close_inherit {
  position: inherit;
  transform: rotate(-45deg);
  font-size: 20px;
  color: white;
  right: 30px;
}

.Data_time p {
  font-weight: 400;
  font-size: 16px;
  margin-top: -4px;
}

.Data_time {
  padding: 13px 9px;
  width: 107px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  height: 40px;
  padding-left: 16px;
  color: black;
  background-color: #ffffff;
}

.Injoy {
  width: 240px;
  padding: 20.5px 37.5px;
  border: 0px;
  background-color: #f6ae2d;
  outline: 0px;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 16px;
}

.Doctor {
  display: flex;
  width: 100%;
}

.Doctor_info {
  flex: 1;
}

.gray {
  color: #8b9bab;
}

.Doctor_full {
  margin-bottom: 40px;
  margin-top: 40px;
  max-width: 830px;
  position: relative;
}

.ButtonMobile {
  display: none;
}

.ButtonMobile button {
  width: 100%;
  display: none;
}

.Consultation_info button {
  width: 200px;
  margin-top: 20px;
}

.Consultation_info {
  margin-left: 30px;
  min-width: 200px;
}

.Doctor_full1 {
  display: flex;
  padding-right: 50px;
  justify-content: space-between;
}

.History_title {
  margin-top: 70px;
  margin-bottom: 32px;
}

.History_select {
  display: flex;
  margin-bottom: 40px;
}

.History_special {
  display: flex;
  width: 70%;
  align-items: center;
  max-width: 440px;
  margin-right: 80px;
}

.History_special p {
  margin-right: 14px;
}

.History_date {
  display: flex;
  max-width: 440px;
  align-items: center;
  width: 70%;
}

.History_date p {
  margin-right: 14px;
}
.Download_Doctor {
  justify-content: space-between;
  padding-right: 0px !important;
}
.Download_file {
  width: 228px;
  height: 221px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eaf2ff;
  border-radius: 24px;
}

.Download_img {
  border-bottom: 2px #9dc3ff solid;
}

.Download_text p {
  width: 161px;
  margin-top: 8px;
  text-align: center;
}

.Download_file {
  width: 228px;
  height: 221px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
}

.Download_img {
  border-bottom: 2px #9dc3ff solid;
}

.Show_more button {
  background: #ffffff;
  border: 1px solid #9dc3ff;
  border-radius: 16px;
  padding: 22px 41px;
  width: 240px;
  height: 60px;
  letter-spacing: 2px;
  text-transform: uppercase;

  color: #8b9bab;
}

.Show_more {
  display: flex;
  margin-left: 192px;
  margin-bottom: 64px;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
}

.Font_size36 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
}
.buy {
  font-size: 24px !important;
}
.Injoy1 {
  width: 240px;
  padding: 20.5px 37.5px;
  border: 0px;
  background-color: #f6ae2d;
  outline: 0px;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 16px;
}

@media (max-width: 980px) {
  .Doctor_cart {
    width: 500px;
  }
  .Show_more {
    display: flex;
    justify-content: center;
    margin-left: 0px;
    margin-bottom: 64px;
  }
  .Consultation_info_text {
    display: none;
  }
  .Consultation_title {
    margin-top: 40px;
  }
  .Doctor_full1 .Consultation_info button {
    display: none;
  }

  .Download_img {
    border-bottom: 1px #9dc3ff solid;
    padding-right: 20px;
  }

  .Doctor_full1 {
    display: flex;
    padding-right: 40px;
  }

  .ButtonMobile {
    display: block;
  }

  .ButtonMobile button {
    width: 100%;
    margin-top: 20px;
    display: block;
  }
}

@media (max-width: 780px) {
  .Download_file {
    align-self: center;
    display: flex;
    width: 100%;
    height: 102px;
    margin-left: 0px;
    flex-direction: row;
  }
  .Doctor_full1 {
    flex-direction: column;
  }
  .Download_file {
    margin-top: 14px;
  }

  .History_special {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .History_date {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }

  .Doctor {
    justify-content: flex-start;
  }

  .Download_img {
    border: 0px;
    border-right: 2px #9dc3ff solid;
  }

  .Download_text p {
    margin-top: 0px;
    margin-left: 20px;
  }

  .Download_img img {
    width: 40px;
    padding-right: 10px;
  }

  .Doctor_full {
    flex-direction: column;
  }
}

@media (max-width: 580px) {
  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }
  .Font_size36 {
    font-family: "Exo", sans-serif;
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }
  .buy {
    font-size: 14px !important;
  }
  .ButtonMobile button {
    max-width: none;
  }
  .History_special {
    display: flex;
    max-width: 100%;
    width: 100%;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .History_select {
    display: block;
    gap: 20px;
    margin-bottom: 40px;
  }

  .History_special {
    align-items: flex-start;
  }

  .History_date {
    max-width: 100%;
    width: 100%;
    align-items: flex-start;
  }

  .Consultation_info {
    margin-left: 0px;
  }

  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 53px !important;
    letter-spacing: 0px !important;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0px !important;
  }

  .Doctor_cart {
    width: 280px;
  }

  .Doctor_avatar_info p {
    display: none;
  }

  .Doctor_avatar_info {
    margin-top: 10px;
  }

  .Doctor_avatar_img {
    width: 70px;
    height: 70px;
  }

  .Doctor_avatar_info ul li img {
    width: 13px;
  }

  .Doctor_avatar {
    margin-right: 10px;
  }

  .Injoy {
    width: 240px;
    padding: 20.5px 37.5px;
    border: 0px;
    margin-left: -80px;
    background-color: #f6ae2d;
    outline: 0px;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 16px;
  }

  .Doctor_avatar {
    width: 80px;
  }

  .Doctor_info {
    width: auto;
  }

  .My_consultation {
    margin-right: 10px;
    margin-left: 10px;
  }

  .Download_file {
    width: 100%;
    height: 82px;
  }

  .Download_file p {
    margin-left: 10px;
    font-size: 14px !important;
    width: auto;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .Show_more button {
    width: 100%;
  }
}
