.Cart_close {
  position: absolute;
  font-size: 30px;
  cursor: pointer;
  right: 27px;
}

.Cancel_Record_full {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0px;
  top: 0px;
  z-index: 3;
}

.Profile_replace_tel_link {
  color: #407bff;
  cursor: pointer;
  margin-left: 15px;
}

.background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  top: 0px;
  width: 100%;
  height: 100%;
}

body.Cancel_Record_full {
  overflow: hidden;
}

.Cancel_Record_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 20px 20px;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding-bottom: 40px;
  border-radius: 16px;
  z-index: 3;
}

.icon_back {
  padding: 11px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 85px;
  text-align: center;
  align-items: center;
  height: 42px;
  width: 42px;
  margin-bottom: 5px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
}

.link_blue {
  cursor: pointer;
}

.moduleIcon {
  display: flex;
  align-items: center;
  color: #ffffff;
  flex-direction: column;
}

.Request {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
}

@media (max-width: 580px) {
  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
  }

  .Cart_close {
    position: absolute;
    font-size: 30px;
    cursor: pointer;
    right: 14px;
    top: 15px;
    width: 16px;
    height: 16px;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
  }

  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }
}
