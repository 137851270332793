.Balance_add input {
  background: #ffffff;
  border: 2px solid #eaf2ff;
  border-radius: 16px;
  margin-right: 30px;
  width: 170px;
  height: 60px;
}

.Balance {
  margin-bottom: 30px;
}

.Message_button_margin {
  margin-top: 30px;
}

.Balance_add {
  margin-top: 25px;
  gap: 20px;
  display: flex;
}

.Balance_add label {
  max-width: 170px;
}

.Balance_friend label {
  width: auto;
}

.Balance_friend {
  margin-top: 25px;
  display: flex;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.Remove_balance {
  display: flex;
  margin-top: 37px;
  margin-bottom: 76px;
}

.Input_friend input {
  margin-top: 19px;
  background: #ffffff;
  border: 2px solid #eaf2ff;
  border-radius: 16px;
  width: 100%;
  height: 60px;
}

.Balance_add button {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  background: #407bff;
  border-radius: 16px;
  width: 200px;
  height: 60px;
  padding: 22px 41px;
  border: 0px;
}

.custom_checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom_checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.Input_friend {
  max-width: 400px;
}

.Referal {
  max-width: 400px;
}
.balanceRefferal {
  background: #ffffff !important;
  border: 2px solid #eaf2ff !important;
  border-radius: 16px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.custom_checkbox + label::before {
  content: "";
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom_checkbox:checked + label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.Referal_title {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.Referal_title img {
  margin-left: 9.5px;
  width: 15px;
  height: auto;
  vertical-align: top;
  cursor: pointer;
}

.Referal input {
  background: #ffffff !important;
  border: 2px solid #eaf2ff !important;
  border-radius: 16px;
  width: 100%;
  height: 60px;
}

.Refferal_input {
  position: relative;
  margin-top: 19px;
  margin-bottom: 70px;
}

.Referal_img {
  position: absolute;
  right: 20px;
  top: 50%;
  cursor: pointer;
  transform: translate(-0%, -50%);
}

.History_title h1 {
  font-size: 40px;
}

.History_data {
  margin-top: 16px;
  font-size: 24px;
  margin-bottom: 7px;
}

.History_content_text b {
  margin-right: 30px;
}

@media (max-width: 630px) {
  .Balance {
    margin-left: 0px;
  }

  .Balance_add input {
    background: #ffffff;
    border: 2px solid #eaf2ff;
    border-radius: 16px;
    margin-right: 20px;
    width: 130px;
  }

  .History_title h1 {
    font-size: 16px;
  }

  .History_content p {
    font-size: 10px;
  }

  .History_content_text b {
    margin-right: 20px;
    font-size: 14px;
  }

  .Referal_title h1 {
    font-size: 16px;
  }

  .Referal_title {
    margin-bottom: 0px;
  }

  .History_data {
    font-size: 14px;
  }

  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
    text-align: left;
  }

  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    text-align: left;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
    text-align: left;
  }

  .Balance_title h1 {
    font-size: 16px;
  }

  .Balance button {
    padding: 13.5px 23.5px;
    font-size: 10px;
    height: 60px;
  }

  .Balance_add label {
    max-width: 130px;
  }
}

@media (max-width: 480px) {
  .Balance button {
    padding: 13.5px 23.5px;
    font-size: 10px;
  }

  .Balance_add input {
    margin-right: 10px;
  }

  .Message_button_margin button {
    width: 100%;
  }
}
