.preloader_body{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1;
}
.lds-heart {
    display: inline-block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: 40px 40px;
}
.lds-heart div {
    top: 32px;
    left: 32px;
    position: absolute;
    width: 50px;
    height: 50px;
    background: #226CE0;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    background: #226CE0;
}
.lds-heart div:before {
    left: -24px;
    border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
    top: -24px;
    border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }
    5% {
        transform: scale(1.1);
    }
    39% {
        transform: scale(0.85);
    }
    45% {
        transform: scale(1);
    }
    60% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(0.9);
    }
}