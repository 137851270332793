
.Cart_close {
    position: absolute;
    font-size: 30px;
    top: 10px;
    cursor: pointer;
    right: 10px;
}

.Cancel_Record_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    align-items: center;
    width: 100%;
    min-width:400px;
    max-width: 800px;
    background-color: #ffffff;
}


.link_blue {
    color: #407BFF;
    cursor: pointer;
    font-size: 14px;
}

@media(max-width: 480px) {
    .title {
        font-size: 14px;
    }

    .link_blue {
        color: #407BFF;
        cursor: pointer;
        font-size: 10px !important;
    }

    .Cancel_Record_full {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        z-index: 100;
    }
    .Cart_close {
        display: none;
    }
    .Cancel_Record_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 16px 16px 0px 0px;
        min-width: 320px;
        padding: 0px 10px;
        background-color: #ffffff;
        height: 400px;
    }
}