.Header_full {
  display: flex;
  width: 100%;
  height: 56px;

  margin-top: 41px;
}

.Header_container {
  margin-left: auto;
  margin-right: auto;
  width: 90.89%;
  max-width: 1920px;
}

.Header_logo {
  margin-right: 10%;
}

.Header_logo img {
  width: 154px;
}

.Header_nav {
  max-width: 435px;
  width: 100%;
}

.Header_nav nav {
  display: flex;
  width: 100%;
}

.navLi:hover {
  border-color: #407bff;
}

.Menu_mobile {
  display: none;
}

.navLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #8b9bab;
  text-decoration: none;
  width: 33%;
  display: flex;
  text-align: center;
  align-items: center;
  border-bottom: 4px solid #eaf2ff;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 14px;
  transition-duration: 0.5s;
}

.navLink:hover {
  color: #2e373d;
  border-color: #407bff;
}
.Menu_mobile img {
  cursor: pointer;
}
.headerActiveLink {
  color: #2e373d;
  border-color: #407bff;
}

.Menu_mobile_title {
  width: 100%;
  white-space: nowrap;
  display: block;
}

.Cancel_Record_button button {
  width: 130px;
  font-weight: bold;
  padding: 22px 41px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 16px;
}

.Cancel_Record_button {
  display: flex;
  justify-content: center;
  gap: 30px;
}

@media (max-width: 980px) {
  .Header_logo {
    margin-right: 5%;
  }

  .Header_nav {
    max-width: 350px;
  }
  .navLink {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .Cancel_Record_button {
    display: flex;
    align-items: center;
  }
  .Header_container {
    width: 100%;
  }
  .Menu_mobile img {
    transform: translate(-0%, -50%);
    margin-top: 15px;
    margin-right: 4px;
  }
  .Menu_mobile_title h1 {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Menu_mobile {
    display: flex;
    width: 100%;
    height: auto;
    min-height: 41px;
    padding: 0px 20px;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
  }

  .Header_full {
    display: none;
  }

  .Menu_mobile_title h1 {
    font-size: 21px;
  }
}

@media (max-width: 480px) {
  .Cancel_Record_button {
    display: flex;
    gap: 10px;
  }
}
