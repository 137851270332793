@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css?family=Exo&display=swap");

#root {
  height: 100%;
}
html {
  height: 100%;
}
body {
  height: 100%;
  font-family: "Exo", sans-serif;
  overflow-x: hidden;
}
a {
  text-decoration: none;
}
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1 1 auto;
}
*::-webkit-scrollbar {
  width: 11px; /* ширина всей полосы прокрутки */
}

*::-webkit-scrollbar-track {
  background: linear-gradient(0deg, #eaf2ff, #eaf2ff),
    linear-gradient(0deg, #eaf2ff, #eaf2ff), #eaf2ff;
  border-radius: 7px;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(0deg, var(--blue), var(--blue)),
    linear-gradient(0deg, #eaf2ff, #eaf2ff), #eaf2ff;
  border-radius: 7px;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--blue) #eaf2ff;
}
.Container {
  max-width: 1260px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .Container {
    margin-top: 20px;
  }
  main .Container {
    margin-bottom: 40px;
  }
  main .Container:first-child {
    margin-top: 20px;
  }
  main .Container:first-child section:not(:first-child) {
    margin-top: 20px;
  }
}
.swiper-slide span[aria-live="polite"] {
  width: 100%;
}
