.footer_full{
    width: 100%;
    height: 126px;
    display: flex;
    width: 90.89%;
    max-width:1920px;
    margin: 0px auto;
    justify-content: space-around;
    align-items: center;
}
.footer_container {
    display: flex;
    justify-content: center;
    background: #407BFF;
}
.footer_img img{
    width: 154px;
}
.footer_text{
    color: white;
}
.footer_link{
    color: white;
}
.footer_Mobile{
    height: 62px;
    display: none;
    width: 100%;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.footer_Mobile ul{
    width: 100%;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    list-style: none;
}
.footer_Mobile ul li{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.footer_Mobile ul li>img{
    width: 15px;
    height: 15px;
}
.navLink {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 2px;
    color: #8B9BAB;
    text-decoration: none;
    transition-duration: 0.5s;
}
@media (max-width:768px) {
    .footer_Mobile{
        display: flex;
        position: fixed;
        bottom: 0px;
        z-index: 2;
        background: #fff;
        margin-top: 30px;
    }
    .headerActiveLink {
        color: #407BFF;
    }
    .footer_full{
        display: none;
    }
}
.footer_Mobile ul li svg,
.footer_Mobile ul li img {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
}
@media (max-width:480px) {
    .footer_Mobile ul li p{
        font-size: 12px;
    }
}
@media (max-width:320px) {
    
    .footer_Mobile ul li svg,
    .footer_Mobile ul li img {
        width: 22px;
        height: 22px;
        margin-bottom: 0;
    }
    .footer_Mobile ul li p{
        display: none;
    }
}