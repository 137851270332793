.Payment_title {
  margin-top: 70px;
  margin-bottom: 25px;
}

.Payment {
  margin-left: 35px;
  margin-right: 35px;
}

input[type="checkbox"]:before {
  position: absolute;
  border: 3px solid #407bff;
  border-radius: 7px;
  content: "";
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  left: -20px;
  top: -4px;
}

input[type="checkbox"]:checked:after {
  position: absolute;
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGzSURBVHgBtZW7S8NQFMa/WyOIOOimWwUXN3F2CF38IxwaECcFWxQEpdQiKk5th05FaAd1chTsUjKIo+3mItjNbmaoj0G9ntM0fdnYhNz+ICT3kfOd+90DR6ALPSmn8YUYJKIQCMMPElX6pwoNKTMlas60aAdPSIM2pemZRjAs/JDIqci0BfQDGaNXGmoxzGNRFGRLGN+oKMi8HwtvmNfI8yQNVAdHM+YktkP0sYTRYQQWmJ0Brnbp2aHvfh+oEkMISHrdDsxCq8t/1wMJGJHerEsPCgU442ikMy6UgbqlUICtcXh6AYrlwftcBaYmgJXFAReHXmsan0DiAq5obgv5LTtI/ZUCXNpZMgtz3qzxdAKGvc5v2lnz3NFaZ8/dI3B9j39xFYif92bGWTslyfDJcjcYiqsAW8IijjUM2+MwzJqhAmhluZGzg3VzS/VeqsATmpdNxVa2fA+ND/eSHITQ9+Wz7+7lFepybFEBo4MExpGlE3i4Lt9Y1MhSIWrQ3EPjUI3EoXkmas0qMk9EgV5xRSfhGAbFzPJAdK/oe9Sfx6iFimYT8teIJGrg+3xH1syIdqK/5H+Du4cmYk4AAAAASUVORK5CYII=);
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  left: -20px;
  top: -4px;
}

.gray {
  color: #8b9bab;
}

.Doctor {
  display: flex;
  margin-top: 40px;
  max-width: 525px;
  justify-content: space-between;
  margin-bottom: 40px;
}

.Payment p,
h1,
h2 {
  color: inherit;
}

.Doctor_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Doctor_avatar_info ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.Select_all {
  display: flex;
}

.Skill_select {
  display: flex;
}

.Doctor_info {
  display: flex;
  max-width: 400px;
  flex-direction: column;
}

.Staj {
  margin-bottom: 11px;
  margin-top: 8px;
}

.Doctor_infos {
  display: flex;
}

.Doctor_avatar_info p {
  text-align: center;
}

.Doctor_avatar {
  width: 162px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Doctor_avatar_img {
  position: relative;
  width: 132px;
  height: 132px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 6px;
}

.Doctor_avatar_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Doctor_info h2 {
  font-size: 24px;
}

.buy {
  margin-bottom: 8px;
  font-weight: 400 !important;
  margin-top: 4px;
}

.link_blue {
  color: #407bff;
  cursor: pointer;
}

.Data_time {
  margin-top: 8px;
}

.Summ {
  margin-top: 35px;
  margin-bottom: 35px;
}

.Summ input {
  border: 1px solid #eaf2ff !important;
  border-radius: 16px;
  background: #ffffff !important;
  width: 100%;
  height: 44px;
}

.Balance p {
  margin-left: 10px;
}

.Oplata {
  width: 240px;
  border-bottom: 3px solid #eaf2ff;
  padding-bottom: 17px;
}

.Oplata span {
  display: flex;
}

.Total_sum {
  max-width: 570px;
  margin-bottom: 71px;
}

.Total_sum span {
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px;
}

.Oplata span {
  margin-top: 11px;
}

.Balance {
  margin-top: 10px;
  margin-left: 20px;
}

.Total_sum button {
  width: 240px;
  border: 0px;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 38px;
  text-align: center;
  height: 60px;
  padding: 22px 41px;
  background: #407bff;
  border-radius: 16px;
}

.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

@media (max-width: 980px) {
  .Payment_title {
    margin-top: 40px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .Payment_title {
    margin-top: 0px;
    margin-bottom: 25px;
  }
}

@media (max-width: 480px) {
  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }

  .Doctor_avatar_img {
    width: 70px;
    height: 70px;
  }

  .Doctor_avatar {
    margin-right: 10px;
    max-width: 70px;
    width: 100%;
  }

  .Oplata span {
    margin-top: 5px;
  }

  .Balance {
    margin-bottom: 12px;
  }

  .Doctor_avatar_info ul {
    max-width: 70px;
  }

  .Doctor_avatar_info ul li {
    width: 20%;
  }

  .Doctor_avatar_info ul li img {
    width: 100%;
  }

  .Summ {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .Total_sum button {
    width: 100%;
    border: 0px;
    color: #ffffff;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 7px;
    text-align: center;
    height: auto;
    padding: 13.5px 41px;
    border-radius: 16px;
  }

  .Staj {
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .buy {
    margin-bottom: 2px;
    margin-top: 3px;
  }

  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 53px !important;
    letter-spacing: 0px !important;
  }

  .Payment {
    margin-right: 10px;
    margin-left: 10px;
  }

  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
  }
}
