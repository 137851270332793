.btn {
  border-radius: 16px;
  width: fit-content;
  border: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 22px 41px;
  font-size: 14px;
  font-weight: 700;
  transition-duration: 0.5s;
  cursor: pointer;
}
.full-width {
  width: 100%;
}
.btn[disabled] {
  cursor: default;
  background-color: #adadad !important;
}
[data-rsbs-header] {
  padding: 18px !important;
}
[data-rsbs-header]::before {
  top: calc(18px + env(safe-area-inset-top)) !important;
}
.blue {
  color: white;
  background-color: var(--blue);
}
.orange {
  color: #2e373d;
  background-color: #f6ae2d;
}
.white {
  color: #8b9bab;
  background: #ffffff;
  border: 1px solid #9dc3ff;
}
.blue:hover {
  background-color: var(--blue-hover);
}
.orange:hover {
  background-color: #f7c469;
}
.white:hover {
  background: #e1e1e1;
  border: 1px solid #9dc3ff;
}
.blue:active {
  background-color: #235ad7;
}
.orange:active {
  background-color: #e99a0c;
}
.white:active {
  background: #adadad;
  border: 1px solid #9dc3ff;
}
@media (max-width: 480px) {
  .btn {
    font-size: 10px;
  }
  .full-width-mobile {
    width: 100%;
  }
}
