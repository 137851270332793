.PrivatePoliceFull {
  padding: 0px 30px;
}
.Container {
  max-width: 1260px;
  margin: 30px auto;
  width: 100%;
}
.Font_size24 {
  font-family: "Exo", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size14 {
  font-family: "Exo", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}
.FileText {
  margin-left: 15px;
}
@media (max-width: 480px) {
  .Font_size24 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 19px !important;
    letter-spacing: 0px !important;
  }
  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 53px !important;
    letter-spacing: 0px !important;
  }
  .Font_size14 {
    font-family: "Exo", sans-serif;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
  }

  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
  }
}
