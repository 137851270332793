input[type="file"] {
    display: none!important;
}
.custom-file-upload {
    display: inline-block;
    cursor: pointer;
}
.upload_file input{
    width: 100%;
}
.upload_file{
    background: #F6AE2D;
    border-radius: 16px;
    border: 0px;
    font-size: 14px;
    line-height: 16px;
    max-width: 400px;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: none;
    margin-top: 14px;
    color: #000;
    font-weight: 700;
    padding: 19px 44px;
}
@media(max-width:980px){
    .upload_file{
        display: block;
    }
}
@media(max-width:480px){
    .upload_file{
        font-size: 10px;
        line-height: 11px;
    }
}