.Profile_data img {
    max-width: 132px;
    border-radius: 50%;
}
.Profile_data_download_img {
    position: relative;
    width: 132px;
    height: 132px;
    border-radius: 50%;
    overflow: hidden;
}
.Profile_data_download_img > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.Profile_data_download_img > img:hover {
    filter: brightness(50%);
    background-color: #e6e6e6;
}
.gray {
    color: #8B9BAB;
}
.upload {
    display: none;
}
.Profile_data_download_img:hover .upload{
    display: flex;
}
.ReplaceData>form {
    margin-top: 30px;
    margin-bottom: 87px;
    display: flex;
    max-width: 400px;
    flex-direction: column;
}

.radio_block p {
    margin-left: 10px;
}

.ReplaceData form button {
    padding: 22px 41px;
    width: 240px;
    height: 60px;
    background: #407BFF;
    border-radius: 16px;
    color: #ffffff;
    border: 0px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.radio_block {
    display: flex;
    align-items: center;
    column-gap: 65px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #8B9BAB;
    margin-bottom: 20px;
}

.upload {
    position: absolute;
    width: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 5px;
}
.radio_block label{
    margin-bottom: 0px!important;
}
.Profile_data {
    display: flex;
    margin-top: 45px;
    align-items: center;
}

.Profile_data b {
    max-width: 280px;
    font-size: 24px;
    margin-left: 30px;
}

.Profile_replace_tel {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.Profile_replace_tel_data {
    display: flex;
    align-items: center;
    margin-top: 7px;
}



.Font_size24 {
    font-family: 'Exo', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
}

.Font_size40 {
    font-family: 'Exo', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0px;

}

.Font_size14 {
    font-family: 'Exo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
}

.Font_size16 {
    font-family: 'Exo', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
}

@media(max-width:580px) {
    .Font_size24 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 19px !important;
        letter-spacing: 0px !important;
    }

    .Font_size40 {
        font-family: 'Exo', sans-serif;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 53px !important;
        letter-spacing: 0px !important;
    }

    .Font_size14 {
        font-family: 'Exo', sans-serif;
        font-size: 10px !important;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
    }

    .Font_size16 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 13px !important;
        letter-spacing: 0px !important;
    }
    .My_content_title{
        display: none;
    }
    .Profile_data{
        margin-top: 0px;
    }
    
    .Profile_data b{
        display: none;
    }
    .ReplaceData form button{
        width: 100%;
    }
    .Profile_data {
        justify-content: center;
    }
    
    .radio_block {
        display: flex;
        align-items: center;
        column-gap: 35px;
        font-weight: 400;
        margin-top: 10px;
        font-size: 16px;
        line-height: 20px;
        color: #8B9BAB;
        margin-bottom: 20px;
    }
}
@media(max-width:480px){
    .ReplaceData form button {
        min-width: 130px;
        border-radius: 16px;
        height: auto;
        color: #ffffff;
        border: 0px;
        margin-left: auto;
        margin-right: auto;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
}