:root {
    --black: black;
    --blueOpacity:rgb(234, 242, 255);
    --title: black;
    --blue:#407bff;
    --blue-hover:#6B99FF;
    --white:#ffffff;
    --green:#22e112;
    --gray: #8b9bab;
    --orange:black;
  }
.black_config{
    color:var(--black);
}
.green_config{
    background:var(--green);
}
.blue_config{
    color:var(--blue)!important;
}
.blue_svg {
    fill:var(--blue)!important;
}
.white_config{
    color:var(--white);
}
.gray_config{
    color:var(--gray)!important;
}
.blueopacity_config{
    color:var(--blueOpacity);
}
.opacityBlue{
    background:var(--blueOpacity);
}
.BackgroundWhite{
    background:var(--white);
}
.title_config{
    color:var(--title); 
}
.BackgroundBlue{
    background:var(--blue);
}
.BackgroundOrange{
    background:var(--orange);
}