
body.Cancel_Record_full {
  overflow: hidden;
}
.Cancel_Record{
  display: flex;
  max-width: 655px;
  flex-direction: column;
  align-items: center;
}
.Cancel_Record h1 {
  margin-top: 32px;
}

.Cancel_close {
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 30px;
  cursor: pointer;
  color: black;
}

.Cancel_Record_button button {
  width: 130px;
  font-weight: bold;
  padding: 22px 41px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 16px;
}

.Cancel_Record_button {
  display: flex;
  gap: 30px;
}

.Font_size40 {
  font-family: "Exo", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0px;
  text-align: left;
}

.Cancel_Record_full{
  display: flex;
  padding: 10px;
  width: 100%;
  min-width: 520px;
  flex-direction: column;
  align-items: center;
}
.background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 21;
  top: 0px;
  width: 100%;
  height: 100%;
}

.Cart_close {
  position: absolute;
  font-size: 30px;
  cursor: pointer;
  right: 27px;
}

.buttonActive {
  background: #f6ae2d;
  cursor: pointer;
  border: 0px;
  margin-right: 30px;
}

.buttonNo {
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #9dc3ff;
}

.Cancel_Record h1 {
  text-align: center;
  margin-bottom: 15px;
}

.Cancel_Record p {
  margin-bottom: 15px;
  text-align: center;
  max-width: 335px;
}

.Font_size16 {
  font-family: "Exo", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
}

@media (max-width: 630px) {
  .Cancel_Record_button {
    display: flex;
    align-items: center;
  }

  .Cancel_Record_block {
    padding: 0px 20px;
    padding-bottom: 20px;
  }

  .buttonActive {
    margin-right: 10px;
    margin-bottom: 0px;
  }
}

@media(max-width:580px) {
  .Font_size40 {
    font-family: "Exo", sans-serif;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    letter-spacing: 0px !important;
  }
  .Cancel_Record_full{
    display: flex;
    padding: 10px;
    width: 100%;
    min-width: 480px;
    flex-direction: column;
    align-items: center;
  }
  .Font_size16 {
    font-family: "Exo", sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    letter-spacing: 0px !important;
  }
}

@media (max-width: 480px) {


  .Cancel_Record_block {
    padding: 0px 20px;
    padding-bottom: 20px;
  }
  .Cancel_Record h1 {
    margin-top: 0px;
  }
  .buttonActive {
    margin-right: 10px;
    margin-bottom: 0px;
  }
}

@media (max-width: 480px) {
  .Cart_close {
    right: 10px;
  }

  .Cancel_close {
    display: none;
  }

  .Cancel_Record_button {
    display: flex;
    gap: 10px;
  }

  .ImgNone {
    display: none;
  }
  .Cancel_Record_full{
    min-width: auto;
  }
}