.VideoBlockImage {
  width: 70%;
  position: relative;
  background-color: #000000;
}

.VideoBlockImage ul {
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1;
  display: none;
  width: 500px;
  justify-content: space-between;
  list-style: none;
  position: absolute;
}

.VideoBlockImage ul > li {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 85px;
  text-align: center;
  align-items: center;
}

.VideoBlockImage ul li .icon_back {
  padding: 11px;
  box-sizing: border-box;
  height: 42px;
  margin-bottom: 5px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
}

.VideoBlockImage ul li > img {
  width: 20px;
}

.VideoBlockImage ul li > p {
  color: #fff;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}

.VideoBlock {
  display: flex;
  padding-bottom: 70px;
  width: 100%;
  height: 70vh;
}

.VideoBlockImageNoVideo {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
}

.VideoBlockImageNoVideo svg {
  width: 20%;
  fill: #ffffff;
  opacity: 0.6;
  display: block;
  height: auto;
}

.ImageVideo1 {
  height: 100%;
}

.ImageVideo {
  position: absolute;
  right: 20px;
  width: 140px !important;
  height: 140px !important;
  bottom: 20px;
}

.TimerAbsolute {
  position: absolute;
  left: 50%;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  top: 20px;
  transform: translate(-50%, 0%);
}

.AbsuluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ButtonStart {
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  background-color: var(--blue);
  border: 0;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
}

.loaderRotate {
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.localUserUp {
  bottom: 120px !important;
}

@media (max-width: 980px) {
  .VideoBlockImage {
    width: 100%;
    height: 70vh;
  }
  .VideoBlock {
    flex-direction: column;
    height: 100%;
    padding-bottom: 40px;
  }
}

@media (max-width: 600px) {
  .VideoBlockImage ul {
    width: 400px;
    bottom: 20px;
  }

  .ImageVideo {
    position: absolute;
    right: 10px;
    width: 120px !important;
    height: 120px !important;
    bottom: 10px;
  }
}

@media (max-width: 480px) {
  .VideoBlock {
    padding-bottom: 0px;
    margin-top: -60px;
    height: calc(100vh + 10px);
  }

  .VideoBlockImage {
    height: 100%;
  }

  .VideoBlockImage img {
    width: 100%;
    border-radius: 0px;
  }

  .VideoBlockImage ul {
    width: 300px !important;
    z-index: 3;
  }

  .VideoBlockImage ul {
    bottom: 70px;
  }

  .ImageVideo1 {
    width: 100%;
    position: relative;
    top: 0px;
    z-index: 2;
    height: 100vh;
    border-radius: 0px;
  }

  .ImageVideo {
    position: absolute;
    right: 20px;
    width: 140px !important;
    height: 140px !important;
    bottom: 60px;
    z-index: 3;
  }

  .TimerAbsolute {
    top: 70px;
  }

  .localUserUp {
    bottom: 160px !important;
  }
}

@media (max-width: 375px) {
  .ImageVideo {
    position: absolute;
    right: 20px;
    width: 110px !important;
    height: 110px !important;
    bottom: 60px;
    z-index: 3;
  }

  .localUserUp {
    bottom: 120px !important;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
