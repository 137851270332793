.Calendar_main ul {
    display: flex;
    list-style: none;
    max-width: 300px;
    overflow-y: hidden;
    padding-bottom: 0.6em;
}
.Calendar_main ul::-webkit-scrollbar {
    width: 0.8em;
    height: 0.8em
}

.Calendar_full {
    margin-top: 30px;
    max-width: 400px;
    width: 100%;
}
.Calendar_active{
    width: 100%;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    height: 40px;
}
.Calendar_main ul li b{
    height: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.Calendar_main ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    min-width: 40px;
}

.Calendar_rasp_top {
    margin-top: 10px;
    display: flex;
    max-width: 400px;
    width: 100%;
    overflow-x: hidden;
    flex-wrap: wrap;
}

.Font_size24 {
    font-family: 'Exo', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
}

.Font_size40 {
    font-family: 'Exo', sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0px;

}


.Calendar_main {
    margin-bottom: 10px;
    margin-top: 10px;
}

.Calendar_rasp {
    width: 86px;
    height: 40px;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background: #EAF2FF;
    border-radius: 15px;
}
.colorUnactive{
    cursor: default;
    background: #EAF2FF;
}
.colorOnClick{
    background: #407BFF;
}
.colorDefault{
    background: #F6AE2D;
}
.Calendar_button button {
    margin-top: 14px;
    width: 100%;
    border: 0px;
    color: #ffffff;
    max-width: 384px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    background: #407BFF;
    border-radius: 10px;
}

.Font_size14 {
    font-family: 'Exo', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
}

.Font_size16 {
    font-family: 'Exo', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
}

@media (max-width:980px) {}

@media (max-width:480px) {
    .Font_size24 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 19px !important;
        letter-spacing: 0px !important;
    }
    .Calendar_rasp {
        width: 23%;
        height: 40px;
        margin-right: 5px;
        margin-top: 5px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        background: #EAF2FF;
        border-radius: 15px;
    }
    .Font_size40 {
        font-family: 'Exo', sans-serif;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 53px !important;
        letter-spacing: 0px !important;
    }

    .Font_size14 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
    }

    .Font_size16 {
        font-family: 'Exo', sans-serif;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 13px !important;
        letter-spacing: 0px !important;
    }
}