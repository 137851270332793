.notFound_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    flex-direction: column;
    gap: 20px;
}
.notFound_title {
    font-size: 22px;
    width: 700;
}
.notFound_description {
    padding: 0 20px;
    font-size: 16px;
    text-align: center;
}
.notFound_action {
    font-size: 16px;
}