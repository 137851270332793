.VideoChat {
  margin-left: 20px;
  position: relative;
  width: 30%;
}

.MessageText input {
  width: 100%;
  max-width: 980px;
  border: 1px solid #eaf2ff !important;
  background: #fff !important;
}

.MessageText img {
  margin-right: 10px;
}

.MessageText {
  width: 100%;
  display: flex;
  align-items: center;
}

.MessageImg {
  position: relative;
  width: 100%;
}

.MessageImg img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-0%, -50%);
  cursor: pointer;
}

@media (max-width: 980px) {
  .VideoChat {
    margin-left: 0px;
    width: 100%;
    height: 40vh;
    margin-top: 20px;
  }

  .MessageText {
    width: 100%;
    padding-bottom: 40px;
  }
}

@media (max-width: 600px) {
  .VideoChat {
    display: none;
  }
}

.VideoFull {
  margin-top: 70px;
  padding: 0px 25px;
}

.VideoTitle {
  margin-bottom: 30px;
}

.VideoBlock {
  display: flex;
  padding-bottom: 70px;
  width: 100%;
  height: 70vh;
}

.VideoBlockImage {
  width: 70%;
  position: relative;
  background-color: #000000;
}

.VideoBlockImageSkeleton {
  background-color: inherit;
}

.VideoBlockImageNoVideo {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
}

.VideoBlockImageNoVideo svg {
  width: 20%;
  fill: #ffffff;
  opacity: 0.6;
  display: block;
  height: auto;
}

.VideoBlockImage ul {
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 1;
  display: none;
  width: 500px;
  justify-content: space-between;
  list-style: none;
  position: absolute;
}

.icon_close {
  background: #f14646 !important;
}

.VideoBlockImage ul > li {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 85px;
  text-align: center;
  align-items: center;
}

.VideoBlockImage ul li .icon_back {
  padding: 11px;
  box-sizing: border-box;
  height: 42px;
  margin-bottom: 5px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
}

.ImageVideo {
  position: absolute;
  right: 20px;
  width: 140px !important;
  height: 140px !important;
  bottom: 20px;
}

.videoMobile {
  display: none;
}

.videoDesctop {
  display: flex !important;
}

.videoDesctop li {
  cursor: pointer;
  width: 85px;
}

.VideoBlockImage ul li > img {
  width: 20px;
}

.icon_back img {
  width: 20px !important;
}

.MessageText input {
  width: 100%;
  max-width: 980px;
  border: 1px solid #eaf2ff !important;
  background: #fff !important;
}

.TimerAbsolute {
  position: absolute;
  left: 50%;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  top: 20px;
  transform: translate(-50%, 0%);
}

.background {
  position: fixed;
  z-index: 1;
  top: 0px;
  width: 100%;
  height: 100%;
}

.VideoChat {
  margin-left: 20px;
  position: relative;
  width: 30%;
}

.VideoBlockImage > img {
  width: 100%;
  border-radius: 30px;
}

.VideoBlockImage ul li > p {
  color: #fff;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
}

.MessageText img {
  margin-right: 10px;
}

.MessageImg {
  position: relative;
  width: 100%;
}

.MessageImg img {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-0%, -50%);
  cursor: pointer;
}

.videoMobile li p {
  font-size: 8px;
}

.Video_Doctor_spec {
  color: #8b9bab;
}

.ImageVideo1 {
  height: 100%;
}

.mobileMessageContainer [data-rsbs-footer] {
  padding: 0;
  overflow: initial;
}

.mobileMessageContainer .MessageText {
  padding: 4px;
  position: initial;
}

.mobileMessageContainer .message__content {
  overflow: auto !important;
  height: 100%;
}

.mobileMessageContainer .mobileVideoChat {
  height: 100%;
}

.mobileMessageContainer *::-webkit-scrollbar-thumb,
.mobileMessageContainer *::-webkit-scrollbar-track {
  background: initial;
  border-radius: initial;
}

.SendFileChat {
  cursor: pointer;
  position: relative;
  height: 62px;
  line-height: 72px;
  padding-left: 6px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SendFileChat img {
  width: auto;
  height: 20px;
}

.SendFileChat .SendFileVariants {
  display: none;
  line-height: initial;
  width: 200px;
  font-size: 14px;
}
.SendFileChat:hover .SendFileVariants {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  position: absolute;
  bottom: 56px;
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  z-index: 9;
  background: #fff;
  left: 0;
}
.SendFileChat .SendFileVariants .SendFileVariant {
  width: 100%;
  padding: 10px;
}
.SendFileChat .SendFileVariants .SendFileVariant:hover {
  background-color: #f1f1f1;
}

.uploadNotice {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.mobileMessageContainer .uploadNotice {
  margin-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
}
.uploadInfo {
  font-size: 14px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.uploadInfoName {
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.uploadInfoSize {
  padding-left: 6px;
  width: fit-content;
}
.uploadProgressBar {
  width: 100%;
  background-color: #d6d6d6;
  margin-top: 4px;
  height: 2px;
}
.uploadProgressPercent {
  height: 2px;
}

@media (max-width: 980px) {
  .VideoBlock {
    flex-direction: column;
    height: 100%;
    padding-bottom: 40px;
  }

  .VideoFull {
    margin-top: 0px;
    padding: 0px 25px;
  }

  .VideoTitle {
    text-align: center;
  }

  .VideoChat {
    margin-left: 0px;
    width: 100%;
    height: 40vh;
    margin-top: 20px;
  }

  .VideoBlockImage {
    width: 100%;
    height: 70vh;
  }

  .MessageText {
    width: 100%;
    padding-bottom: 0;
  }
}

@media (max-width: 600px) {
  .videoMobile {
    display: flex !important;
  }

  .ImageVideo {
    position: absolute;
    right: 10px;
    width: 120px !important;
    height: 120px !important;
    bottom: 10px;
  }

  .VideoChat {
    display: none;
  }

  .VideoFull {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .videoDesctop {
    display: none !important;
  }

  .VideoBlockImage ul {
    width: 400px;
    bottom: 20px;
  }

  .videoMobile li {
    max-width: 40px !important;
  }
}

@media (max-width: 480px) {
  .VideoFull {
    padding: 0px 0px;
    margin-top: 0px;
  }

  .TimerAbsolute {
    top: 70px;
  }

  .VideoTitle {
    display: none;
  }

  .VideoBlockImage {
    height: 100%;
  }

  .VideoFull {
    height: 100%;
  }

  .VideoBlock {
    padding-bottom: 0px;
    margin-top: -20px;
    height: calc(100vh + 20px);
  }

  .VideoBlockImage img {
    width: 100%;

    border-radius: 0px;
  }

  .ImageVideo1 {
    width: 100%;
    position: relative;
    top: 0px;
    z-index: 2;
    height: 100vh;
    border-radius: 0px;
  }

  .ImageVideo {
    position: absolute;
    right: 20px;
    width: 140px !important;
    height: 140px !important;
    bottom: 60px;
    z-index: 3;
  }

  .VideoBlockImage ul {
    width: 300px !important;
    z-index: 3;
  }

  .VideoBlockImage ul {
    bottom: 70px;
  }

  .MessageText {
    padding-bottom: 0;
  }
}

.mobileVideoChat {
  display: block;
}
