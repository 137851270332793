.jare {
  margin-left: auto;
  position: relative;
}
.Header_profile {
  display: flex;
  align-items: center;
  align-self: flex-end;
}
.text_notice {
  padding: 10px 30px;
  font-weight: normal;
  color: #8b9bab;
  border-bottom: 1px solid #eaf2ff;
}
.notice_back {
  position: fixed;
  cursor: default;
  z-index: 20;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.notice_body {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-top: 10px;
  width: 500px;
  top: 90px;
  right: 45px;
  z-index: 1;
  max-height: 275px;
  overflow: auto;
}
.notice_item {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  text-decoration: none;
  padding-bottom: 7px;
  border-bottom: 1px solid #eaf2ff;
  padding: 10px 16px;
}
.notice_item:hover {
  background-color: #ebebeb2f;
}
.notice_item_svg {
  margin-top: 5px;
}
.notice_item_block {
  max-width: 400px;
}
.notice_item_block_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #2e373d;
  margin-bottom: 8px;
}
.notice_item_block_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2e373d;
  margin-bottom: 10px;
}
.notice_item_block_date {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8b9bab;
}
.notice_readAll {
  padding: 15px 0 0 30px;
  background-color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #407bff;
  text-decoration: none;
  padding-bottom: 10px;
  position: sticky;
  bottom: 0;
}
.Profile_logo {
  position: relative;
  cursor: pointer;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition-duration: 0.3s;
}
.Profile_logo:hover {
  background-color: #f3f3f3;
}
.Profile_logo:active {
  background-color: #cfcfcf;
}
.Profile_text {
  margin-left: 20px;
  display: flex;
  gap: 10px;
  cursor: pointer;
}
.Profile_text img {
  width: 40px;
}
.Profile_avatar {
  position: relative;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
}
.Profile_avatar img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.Profile_menu {
  align-items: center;
  display: flex;
  gap: 7px;
  user-select: none;
}
.Profile_menu img {
  width: 16px;
  height: 9px;
}
.MenuWrap {
  position: absolute;
  background: #ffffff;
  z-index: 99;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  width: 250px;
  right: 0px;
  top: 70px;
}
.MenuList {
  list-style: none;
}
.MenuLink {
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #2e373d;
  text-decoration: none;
  transition-duration: 0.5s;
  padding: 2px 16px;
}
.MenuLink:hover {
  background-color: rgba(224, 224, 224, 0.17);
}
.MenuBack {
  position: relative;
  width: 100%;
  height: 100%;
  top: 20px;
  z-index: 20;
}
.LinkBorder {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}
